import { Col, Row, ListGroup } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./adminAuth.scss";
import { useState } from "react";
import { resetToken } from '../../pages/po-home/PlaceOwnerHomeSlice'
import { useDispatch } from "react-redux";
import { resetPlaceData } from "../../pages/po-home/PlaceOwnerHomeSlice";

const PoProtected = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const href = window.location.href;
  const token = localStorage.getItem("owner-emv-token");
  const activeLink = (path) => (href.endsWith(path) ? "active" : "");
  const handleSHow = () => {
    setShow(!show);
  };

  const handleLogout = async () => {
    localStorage.clear();
    dispatch(resetToken())
    navigate("/business/signin");
    setShow(false);
    await dispatch(resetPlaceData())
  };
  return (
    <>
      {token === null ? (
        <Navigate to="/business/signin" />
      ) : (
        <>
          <div className="mobile-sidebar">
            <img
              role="button"
              src={`${process.env.PUBLIC_URL}/assets/menu.svg`}
              height="30px"
              onClick={handleSHow}
            />
          </div>
          <Row className="main-warap-div d-flex m-0 ">
            <div
              className={
                show === true
                  ? "mobile-open-sidebar web-sidebar"
                  : "web-sidebar"
              }
            >
              {/* <NavBar color="#1C3879E5"/> */}
              <div className="web-sidebar-logo">
                <img
                  role="button"
                  src={`${process.env.PUBLIC_URL}/assets/emvite-logo.svg`}
                  alt="emvita logo"
                />
                <div className="mobile-sidebar-close">
                  <img
                    role="button"
                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                    height="30px"
                    onClick={handleSHow}
                  />
                </div>
              </div>
              <ul>
                <li>          
                <ListGroup.Item as={Link} to="/business/home" className={activeLink("/business/home")}>Home
                  </ListGroup.Item>
                </li>
              </ul>
              <button
                className="web-logout-btn"
                type="button"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
            <Col className="wrap-content">{props.children}</Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PoProtected;
