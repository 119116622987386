import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
const initialState = {
  foodData: [],
  imageGallery: [],
  maximumGalleryUploadSize: 7,
  isGalaryUpload: false,
  currentIndex: 1,
  imageGalaryIndex: null,
  totalCount: 0,
  searchLoading: false,
  placeDetail: []
};
export const getFoodData = createAsyncThunk(
  "/getData",
  async ({ page, limit, searchKey }) => {
    const query = new Parse.Query("Places");
    let count = await query.count();
    query.limit(limit);
    query.skip(page * limit);
    if (searchKey != "") {
      query.contains("placeName", searchKey)
      query.matches("placeName", searchKey, 'i')
    }
    const response = await query.find();
    return { response, count };
  }
);
export const fetchGallaryImages = createAsyncThunk(
  "/fetchGallaryImages",
  async ({ placeId }) => {
    console.log("placeId", placeId)
    const query = new Parse.Query("Places_Gallery");
    query.equalTo("placeId", placeId);
    const response = await query.find();
    return response;
  }
);

export const fetchPlaceDetailAsync = createAsyncThunk(
  "/fetch-place-detail",
  async ({objectId}) => {
    const query = new Parse.Query("Places");
    query.equalTo("objectId", objectId);
    const response = await query.find();
    return response;
  }
);
const foodSlice = createSlice({
  name: "food",
  initialState: initialState,

  reducers: {
    toggleGalaryImageUploadFlag(state, action) {
      state.isGalaryUpload = action.payload;
    },
    addGalaryImage(state, action) {
      //add image in imageGallary array
      if (state.isGalaryUpload == true) {
        state.imageGalary.push(...state.imageGallery, action.payload);
        state.isGalaryUpload = false;
      } else {
        state.imageGalary = [action.payload];
      }
    },
    updateIndex(state, action) {
      if (state.currentIndex <= state.foodData.length) {
        state.currentIndex = action.payload;
      }
    },
    incrementIndex(state, action) {
      if (state.currentIndex < state.foodData.length - 1) {
        state.currentIndex = state.currentIndex + 1;
      }
    },
    decrementIndex(state, action) {
      if (state.currentIndex > 0) {
        state.currentIndex = state.currentIndex - 1;
      }
    },
    setImageGalaryIndex(state, action) {
      state.imageGalaryIndex = action.payload;
    },
    incrementImageGalaryIndex(state, action) {
      if (state.imageGalaryIndex !== null) state.imageGalaryIndex += 1;
    },
    decrementImageGalaryIndex(state, action) {
      if (state.imageGalaryIndex !== null && state.imageGalaryIndex != 0)
        state.imageGalaryIndex -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getFoodData.pending, (state) => {state.searchLoading = true; })
      .addCase(getFoodData.fulfilled, (state, action) => {
        const allData = action.payload.response;
        const count = action.payload.count;
        let newData = [];
        allData.forEach((item) => {
          newData.push(
            Object.assign({ ...item.attributes }, { ObjectId: item.id })
          );
        });
        state.foodData = newData;
        state.totalCount = count;
        state.searchLoading = false
      })
      .addCase(fetchGallaryImages.fulfilled, (state, action) => {
        const allData = action.payload;
        let newData = [];
        allData.forEach((item) => {
          // if( typeof item.attributes.images === "object"){
          //   state.imageGalaryArray.push(item.attributes.images._url);
          // }

          newData.push(
            Object.assign({ ...item.attributes }, { ObjectId: item.id })
          );
        });
        state.imageGallery = newData;
      })
      .addCase(fetchPlaceDetailAsync.fulfilled, (state, action) => {
        const oldPlaceData = action.payload;
        let newPlaceData = [];
        oldPlaceData.forEach((item) => {
          newPlaceData.push(
            Object.assign({ ...item.attributes }, { ObjectId: item.id })
          );
        });
        state.placeDetail = newPlaceData[0];
      });
  },
});
export const getData = (state) => {
  return state.food.foodData;
};
export const getGalleryImageWithId = (i) => (state) => {
  const data = state.food.imageGallery.filter((item, index) => index == i);
  return data[0].galleryImage._url;
};

export const getRestaurantWithId = (i) => (state) => {
  const data = state.food.foodData.filter((item, index) => index == i);
  return data[0];
};

export const getFoodSize = (state) => state.food.foodData.length;
export const getGallaryImages = (state) => {
  return state.food.imageGallery;
};
export const getCurrentIndex = (state) => {
  return state.food.currentIndex;
};
export const getMaximumGalarySize = (state) => {
  return state.food.maximumGalleryUploadSize;
};

export const getGalleyIndex = (state) => {
  return state.food.imageGalaryIndex;
};

export const getTotalCount = (state) => state.food.totalCount
export const selectSearchLoading = (state) => state.food.searchLoading
export const selectPLaceDetail = (state) => state.food.placeDetail

export default foodSlice.reducer;
export const {
  addGalaryImage,
  updateIndex,
  decrementIndex,
  incrementIndex,
  setImageGalaryIndex,
  toggleGalaryImageUploadFlag,
  incrementImageGalaryIndex,
  decrementImageGalaryIndex,
} = foodSlice.actions;
