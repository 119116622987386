import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import Parse from "parse";

const initialState = {
    userData: [],
    mediaData: [],
    token: null,
    status: false,
    resMessage: null,
    ownerShipData: [],
    placeData: [],
    dataForMap: [],
    galleryData: [],
    ownerDocData: [],
    totalViewed: 0,
    totalSuggested: 0,
    docStatus: false,
    isLoading: false
};

export const getInstaUserData = createAsyncThunk("/po-home", async (id) => {
    const data1 = {id: id}
  try {
    const response = await Parse.Cloud.run("instaUserData", data1);
    return response;
  } catch (err) {
    console.log(err);
  }
});

export const postOwnerShipAsync = createAsyncThunk(
  "/owner-ship",
  async (values) => {
    const newObject = new Parse.Object("PlaceOwnerShipRequest");
    newObject.set("placeOwnerId", values.placeOwnerShipId);
    newObject.set("placeName", values.placeName);
    try {
      const response = await newObject.save();
      return response;
    } catch (error) {
      console.error("Error while creating records: ", error);
    }
  });

  export const postOwnerDocAsync = createAsyncThunk(
    "/place-owner-doc",
    async ({parseFile, id}) => {
      const newObject = new Parse.Object("PlaceOwnerDocument");
      newObject.set("file", parseFile);
      newObject.set("ownerId", id);
      try {
        const response = await newObject.save();
        return response;
      } catch (error) {
        console.error("Error while creating records: ", error);
      }
    });

    export const getOwnerDocDataAsync = createAsyncThunk(
      "/get-owner-doc-data",
      async (id) => {
        var ownerId = id
        const query = new Parse.Query("PlaceOwnerDocument");
        query.equalTo('ownerId', ownerId);
        try {
          const response = await query.find();
          return response;
        } catch (error) {
          console.error("Error while creating records: ", error);
        }
      });

  export const getOwnerShipAsync = createAsyncThunk(
    "/get-owner-ship",
    async (id) => {
      var placeOwnerId = id
      const query = new Parse.Query("PlaceOwnerShipRequest");
      query.equalTo('placeOwnerId', placeOwnerId);
      try {
        const response = await query.find();
        return response;
      } catch (error) {
        console.error("Error while creating records: ", error);
      }
    });

    export const deleteOwnerDoc = createAsyncThunk(
      "/delete-owner-doc",
      async ({deleteDocId}) => {
        var objectId = deleteDocId
        try {
          const query = new Parse.Object("PlaceOwnerDocument");
          query.set('objectId', objectId);
          const response = await query.destroy();
          return response
        } catch (error) {
          console.error("Error while creating records: ", error);
        }
      });

  export const getPlaceDataAsync = createAsyncThunk(
      "/get-place-data",
      async (id) => {
        var ownerId = id
        const query = new Parse.Query("Places");
        query.equalTo('ownerId', ownerId);
        try {
          const response = await query.find();
          return response;
        } catch (error) {
          console.error("Error while creating records: ", error);
        }
      });
      
      export const getGallerryDataAsync = createAsyncThunk(
        "/get-gallery-data",
        async ({id}) => {
          var placeId = id
          const query = new Parse.Query("Places_Gallery");
          query.equalTo('placeId', placeId);
          try {
            const response = await query.find();
            return response;
          } catch (error) {
            console.error("Error while get records: ", error);
          }
        });
        
        export const getPlaceActivityViewedDataAsync = createAsyncThunk(
          "/get-place-activity-viewed",
          async ({placeId}) => {
            try {
              const query = new Parse.Query("Place_Activity");
              query.equalTo('placeId', placeId);
              query.equalTo('type', "viewed")
              let count = await query.count();
              const response = await query.find();
              return {response, count};
            } catch (error) {
              console.error("Error while get records: ", error);
            }
          });

          export const getPlaceActivitySuggestedCountAsync = createAsyncThunk(
            "/get-place-activity-suggested",
            async ({placeId}) => {
              try {
                const query = new Parse.Query("Place_Activity");
                query.equalTo('placeId', placeId);
                query.equalTo('type', "suggested")
                let count = await query.count();
                const response = await query.find();
                return {response, count};
              } catch (error) {
                console.error("Error while get records: ", error);
              }
            });

            export const getPlaceActivityDemographicAsync = createAsyncThunk(
              "/get-place-activity-Demographic",
              async ({placeId}) => {
                try {
                  const query = new Parse.Query("Place_Activity");
                  query.equalTo('placeId', placeId);
                  query.equalTo('from', 'Place')
                  query.limit(1000)
                  let count = await query.count();
                  const response = await query.find();
                  return {response, count};
                } catch (error) {
                  console.error("Error while get records: ", error);
                }
              });

const poHomeSlice = createSlice({
  name: "pohome",
  initialState: initialState,

  reducers: {
    resetToken: (state, action) => {
      state.token = null
    },
    resetStatus: (state, action) => {
      state.status = false
    },
    resetPlaceData: (state, action) => {
      state.placeData = []
    },
    resetDocStatus: (state, action) => {
      state.docStatus = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstaUserData.fulfilled, (state, action) => {
        state.token = action.payload[0].accessToken
        state.mediaData = JSON.parse(Object.values(action.payload[1]))
        state.userData = JSON.parse(Object.values(action.payload[2]))
      })
      .addCase(postOwnerShipAsync.pending, (state, action) => { state.status = false;})
      .addCase(postOwnerShipAsync.fulfilled, (state, action) => {
        state.status = true
      })
      .addCase(getOwnerShipAsync.pending, (state, action) => { state.status = false; state.resMessage = null})
      .addCase(getOwnerShipAsync.fulfilled, (state, action) => {
        const allData = action.payload;
        let newData = [];
        allData.forEach((item) => {
          newData.push(
            Object.assign({ ...item.attributes })
          );
        });
        state.ownerShipData = newData;
      })
      .addCase(getPlaceDataAsync.pending, (state, action) => { state.status = false; state.resMessage = null})
      .addCase(getPlaceDataAsync.fulfilled, (state, action) => {
        const allData = action.payload;
        let data = [];
        allData.forEach((item) => {
          data.push(
            Object.assign({ ...item.attributes }, {objectId: item.id})
          );
        });
        state.placeData = data;
      })
      .addCase(getGallerryDataAsync.fulfilled, (state, action) => {
        const oldData = action.payload;
        let newData = [];
        oldData.forEach((item) => {
          newData.push(
            Object.assign({ ...item.attributes }, { objectId: item.id })
          );
        });
        state.galleryData = newData;
      })
      .addCase(getPlaceActivityViewedDataAsync.fulfilled, (state, action) => {
        state.totalViewed = action.payload.count
      })
      .addCase(getPlaceActivitySuggestedCountAsync.fulfilled, (state, action) => {
        state.totalSuggested = action.payload.count
      }) 
      .addCase(getPlaceActivityDemographicAsync.fulfilled, (state, action) => {
        const oldData = action.payload.response;
        let newData = [];
        oldData.forEach((item) => {
          newData.push(
            Object.assign({ ...item.attributes }, { objectId: item.id })
          );
        });
        state.dataForMap = [...newData];
      })
      .addCase(postOwnerDocAsync.pending, (state, action) => {state.docStatus = false; state.isLoading = true})
      .addCase(postOwnerDocAsync.fulfilled, (state, action) => {
        state.docStatus = true;
        state.isLoading = false
      })
      .addCase(deleteOwnerDoc.pending, (state, action) => { state.docStatus = false; state.isLoading = true})
      .addCase(deleteOwnerDoc.fulfilled, (state, action) => {
        state.docStatus = true;
        state.isLoading = false
      })
      .addCase(getOwnerDocDataAsync.fulfilled, (state, action) => {
        const oldData = action.payload;
        let newData = [];
        oldData.forEach((item) => {
          newData.push(
            Object.assign({ ...item.attributes }, { objectId: item.id })
          );
        });
        state.ownerDocData = newData;
      })
  },
});

export const { resetToken, resetStatus, resetPlaceData, resetDocStatus } = poHomeSlice.actions;

  export const selectUserData = state => state.pohome.userData
  export const selectMediaData = state => state.pohome.mediaData
  export const selectToken = state => state.pohome.token
  export const selectStatus = state => state.pohome.status
  // export const selectMessage = state => state.pohome.resMessage
  export const selectOwnerData = state => state.pohome.ownerShipData
  export const selectPlaceData = state => state.pohome.placeData
  export const selectGalleryData = state => state.pohome.galleryData
  export const selectViewedCount = state => state.pohome.totalViewed
  export const selectSuggestedCount = state => state.pohome.totalSuggested
  export const selectDocData = state => state.pohome.ownerDocData
  export const selectDocStatus = state => state.pohome.docStatus
  export const selectIsLoading = state => state.pohome.isLoading
  export const selectDemograpicData = state => state.pohome.dataForMap
export default poHomeSlice.reducer;
