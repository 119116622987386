import { Col, Row, Card, Form, Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./PlaceOwnerIstaDetail.scss";
import {
  getInstaUserData,
  selectUserData,
  selectMediaData,
  selectToken,
} from "../po-home/PlaceOwnerHomeSlice";
import {
  getOwnerShipListAsync,
  selectOwnerShipData,
} from "../admin-owner-request/OwnerRequestSlice";
import {
  getOwnerShipDetailAsync,
  selectAdminPLaceOwnerData,
  getOwnerShipUserPlaceDetailAsync,
  selectAdminPLaceOwnerPLaceNameData,
  getPlacesListAsync,
  selectPlacesData,
  selectSearchLoading,
  postTransferOwnerAsync,
  selectStatus,
  resetStatus,
  sendTransferEmailAsync,
  selectMessage,
  resetMessage
} from "./PlaceOwnerInstaDetailSlice";
import { useParams } from "react-router-dom";
import { EmojiIcons } from "../../constant";

export const debounceWait = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const loading = (
  <div className="loader">
    <div className="fp-container">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
);

const PlaceOwnerInstaDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const mediaData = useSelector(selectMediaData);
  const userData = useSelector(selectUserData);
  const token = useSelector(selectToken);
  const ownerData = useSelector(selectOwnerShipData);
  const Place_Owner = useSelector(selectAdminPLaceOwnerData);
  const PLace_Name = useSelector(selectAdminPLaceOwnerPLaceNameData);
  const place = useSelector(selectPlacesData);
  const isLoading = useSelector(selectSearchLoading);
  const status = useSelector(selectStatus)
  const message = useSelector(selectMessage)
  const [searchKey, setSearchKey] = useState("");
  const [transferPopup, setTransferPopup] = useState(false);
  const [transferOwnerShipData, setTransferOwnerShipData] = useState();
  const [transferEmailResPopup, setTransferEmailResPopup] = useState(false)

  const handleClose = () => {setTransferPopup(false); setTransferOwnerShipData()}

  const handleTransferResClose = () => {setTransferEmailResPopup(false); dispatch(resetMessage())}

  useEffect(() => {
    dispatch(getInstaUserData(id));
    dispatch(getOwnerShipListAsync());
    dispatch(getOwnerShipDetailAsync(id));
    dispatch(getOwnerShipUserPlaceDetailAsync(id));
    dispatch(getPlacesListAsync(searchKey));
  }, [id, searchKey]);

  const handleChange = debounceWait((e) => searchCategory(e));

  const searchCategory = async (e) => {
    setSearchKey(e.target.value);
  };

  const handleTransferOnerShip = (item) => {
    setTransferOwnerShipData(item);
    setTransferPopup(true);
  };

  const handleTransferOnerShipSubmit = async (id) => {
    let placeOwnerId = PLace_Name[0].placeOwnerId
    let placeName = PLace_Name[0].placeName
    await dispatch(postTransferOwnerAsync({id, placeOwnerId}))
    await dispatch(sendTransferEmailAsync({placeOwnerId, id, placeName}))
  }

  useEffect(() => {
    if(status && status === true){
      setTransferPopup(false)
    }
    dispatch(resetStatus())
  },[status])

  useEffect(() => {
    if(message && message != ""){
      setTransferEmailResPopup(true)
    }
  },[message])

  const getIcons = (name) => {
    let foodItems = EmojiIcons.foodItemsList.find((fi) => fi.name === name);
    let activityItems = EmojiIcons.activityItemsList.find((ai) => ai.name === name);
    if(foodItems) return foodItems.image;
    if(activityItems) return activityItems.image;
  }

  return (
    <>
      {isLoading && loading}
      <div className="place-owner-details">
        <div className="overlay-img">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
          />
        </div>
        <div className="overlay-shap">
          <img src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`} />
        </div>
        <Row className="w-100 h-100">
          <Col md={5} xs={12} className="h-100">
            <Card className="landing-col">
              <div>
                User Name : <span>@{userData.username}</span>
              </div>
              <div>
                Owner Name :{" "}
                {Place_Owner.map((t) => (
                  <span>{t.name}</span>
                ))}
              </div>
              <div>
                Place Name :{" "}
                {PLace_Name.map((t) => (
                  <span>{t.placeName}</span>
                ))}
              </div>
              <div className="user-media">
                {mediaData.map((d, i) => (
                  <div className="user-media-detail" key={i}>
                    <img src={d.media_url} />
                  </div>
                ))}
              </div>
            </Card>
          </Col>
          <Col md={7} xs={12} className="places-card">
            <Card className="right-landing-col">
              <div>
                <Form.Group
                  className="search-with-icon mb-3"
                  controlId="formBasicSearch"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/Search-gray.svg`}
                    alt="searchicon"
                  />
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    autoComplete="off"
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <Row>
                {place &&
                  place.length > 0 ?
                  place.map((menu, i) => (
                    <Col md={6}>
                      <Card>
                        <div className="card-container">
                          {menu.icons ? (
                            <div className="emojiContainer">
                              {menu.icons.map((t, i) => (
                                getIcons(t) != undefined ? <img
                                  key={i}
                                  className="icons-img me-2"
                                  src={`${process.env.PUBLIC_URL}/assets/Emojis/${getIcons(t)}`}
                                /> : ''
                              ))}
                              {/* <img className="icons-img" src={`${process.env.PUBLIC_URL}/assets/Emojis/${menu.icons}.png`} width="100%" /> */}
                            </div>
                          ) : (
                            ""
                          )}
                          <img className="img" src={menu.images._url} />
                        </div>
                        <div className="card-header">
                          <Card.Title>{menu.placeName}</Card.Title>
                          <div className="card-header-btn">
                          <Button  onClick={() => handleTransferOnerShip(menu)}>
                            Transfer Owner Ship
                          </Button>
                          </div>
                        </div>
                        <Card.Body>
                          <Row>
                            <Col className="description">
                              {menu.description}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  )) : <div className="d-flex justify-content-center align-items-center fs-4 fw-bold">Data Not Found!</div>}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal centered size="lg" className="owner-ship-transfer-modal" show={transferPopup} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Card>
                <div className="card-container">
                  {transferOwnerShipData && transferOwnerShipData.icons ? (
                    <div className="emojiContainer">
                      {transferOwnerShipData &&
                        transferOwnerShipData.icons.map((t, i) => (
                          <img
                            key={i}
                            className="icons-img me-2"
                            src={`${process.env.PUBLIC_URL}/assets/Emojis/${t}.png`}
                          />
                        ))}
                      {/* <img className="icons-img" src={`${process.env.PUBLIC_URL}/assets/Emojis/${menu.icons}.png`} width="100%" /> */}
                    </div>
                  ) : (
                    ""
                  )}
                  <img
                    className="img"
                    src={
                      transferOwnerShipData && transferOwnerShipData.images._url
                    }
                  />
                </div>
                <div className="card-header d-flex justify-content-between align-items-center">
                  <Card.Title>
                    {transferOwnerShipData && transferOwnerShipData.placeName}
                  </Card.Title>
                  {/* <Button onClick={() => handleTransferOnerShip(menu)}>Transfer Owner Ship</Button> */}
                </div>
                <Card.Body>
                  <Row>
                    <Col className="description">
                      {transferOwnerShipData &&
                        transferOwnerShipData.description}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
            <div className="owner-request-message">
             {` You want to transfer 
                ${PLace_Name.map((t) => (
                  t.placeName
                ))} owner ship to 
                ${Place_Owner.map((t) => (
                  t.name
                ))}
                (@${userData.username})`}
            </div>
            <div className="owner-request-message-ownername">
            Owner Name :{" "}
                {Place_Owner.map((t) => (
                  <span>{t.name}</span>
                ))}
            </div>
            <div className="owner-request-message-placename">
            Place Name :{" "}
                {PLace_Name.map((t) => (
                  <span>{t.placeName}</span>
                ))}
            </div>
            <div className="owner-request-message-placename">
            User Name : <span>@{userData.username}</span>
                </div>
            </Col>
          </Row>
          <Row>
            <div className="text-end taransfer-owner-modal-btn">
            <Button className="me-2 taransfer-owner-modal-btn-close" variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" className="taransfer-owner-modal-btn-save" type="submit" onClick={() => handleTransferOnerShipSubmit(transferOwnerShipData.ObjectId)}>
            Yes
          </Button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal centered className="response-msg-modal" show={transferEmailResPopup} onHide={handleTransferResClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="response-msg-modal-body">
          <h4>{message}</h4>
          <div className="response-msg-modal-btn">
          <Button className="response-msg-btn" variant="secondary" onClick={handleTransferResClose}>
            Close
          </Button>
          </div>
          </Modal.Body>
      </Modal>
    </>
  );
};

export default PlaceOwnerInstaDetail;
