import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";

const initialState = {
  status: false,
  loading: false,
  msg: "",
};

export const sendEmailAsync = createAsyncThunk("/send-email", async (email) => {
  const params1 = { toEmail: email };
  try {
    const response = await Parse.Cloud.run("softLaunchInvite", params1);
    return response;
  } catch (error) {
    console.error("Error while creating records: ", error);
  }
});

const subscribeSlice = createSlice({
  name: "invite",
  initialState: initialState,

  reducers: {
    changeMsg: (state) => {
      state.msg = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendEmailAsync.fulfilled, (state, action) => {
        console.log("message", action.payload);
        state.msg = action.payload.replace("message ", "");
        state.status = true;
        state.loading = false;
      })
      .addCase(sendEmailAsync.pending, (state, action) => {
        state.status = false;
        state.loading = true;
      });
  },
});


export const { changeMsg } = subscribeSlice.actions;

export const selectMsg = (state) => state.invite.msg;
export const selectStatus = (state) => state.invite.status;

export default subscribeSlice.reducer;
