import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from 'react-bootstrap'
import { useNavigate, useParams } from "react-router-dom";
import { poResetPasswordAsync, resetPasswordMsg } from './PoResetPasswordSlice'
import { useEffect } from "react";
import { toast } from "react-toastify";

const passRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*(?=.*[#$^+=!*()@%&]).{8,15}$"
  );

const PoResetPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const resetMessage = useSelector(resetPasswordMsg)
    const { token } = useParams();

    useEffect(() => {
        if(resetMessage && resetMessage != ""){
          toast.success(resetMessage)
          navigate('/business/signin')
        }
    }, [resetMessage])
    return (
        <>
        <div >
      <Formik
        initialValues={{ newPassword: "", confirmPassword: ""}}
        validate={(values) => {
          const errors = {};
          if (
            !values.newPassword ||
            values.newPassword.trim().length === 0 ||
            values.newPassword.trim().length > 15 ||
            values.newPassword.trim().length < 8
          )
            errors.newPassword = "Min 8 and Max 15 char required";
          else if (!passRegex.test(values.newPassword))
            errors.newPassword = "Required 1 Caps, 1 small, 1 special character, 1 number";
      
          if (
            !values.confirmPassword ||
            values.confirmPassword.trim().length === 0 ||
            values.confirmPassword.trim().length > 15 ||
            values.confirmPassword.trim().length < 8
          )
            errors.confirmPassword = "Required";
          else if (!passRegex.test(values.confirmPassword))
            errors.confirmPassword =
              "Required 1 Caps, 1 small, 1 special character, 1 number";
          else if (values.confirmPassword !== values.newPassword)
            errors.confirmPassword = "Password do not match";

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await dispatch(poResetPasswordAsync({password: values.confirmPassword, forgotPasswordToken: token}))
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <>
          <div className="login-main">
          <div className="logo-header">
              <img
                src={`${process.env.PUBLIC_URL}/assets/emvite-logo.svg`}
              />
          </div> 
          <div className="overlay-img">
              <img
                src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
              />
          </div>
          <div className="overlay-shap">
          <img
                src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`}
              /> 
          </div>
            <div className="login-form-main">
                <Form onSubmit={handleSubmit}>
                  
            <div className="login-form-heading">
                <h1>Reset Password</h1>
              </div>
                  <Form.Group
                    className="mb-3"
                    controlId="newPassword"
                    style={{ position: "relative" }}
                  >
                    <Form.Label className="loginLabel">New Password</Form.Label>
                    <div className="login-control">
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      className="emailTextBox"
                      onChange={handleChange}
                   
                    />
                    <span className="login-control-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/Login form icons/PasswordIcon.svg`}
                      height="30px"
                      width="30px"
                      className="emailIcon"
                    />
                    </span>
                    </div>
                    {errors.newPassword !== undefined?<p className="text-danger">{errors.newPassword}</p>:null}
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="confirmPassword"
                    style={{ position: "relative" }}
                  >
                    <Form.Label className="loginLabel">Confirm Password</Form.Label>
                    <div className="login-control">
                    <Form.Control
                      type="password"
                      placeholder="Enter confirm password"
                      className="emailTextBox"
                      onChange={handleChange}
                   
                    />
                    <span className="login-control-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/Login form icons/PasswordIcon.svg`}
                      height="30px"
                      width="30px"
                      className="emailIcon"
                    />
                    </span>
                    </div>
                    {errors.confirmPassword !== undefined?<p className="text-danger">{errors.confirmPassword}</p>:null}
                  </Form.Group>
                  <div className="loginBtnContainer">
                    <Button type="submit" className="loginBtn">
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          
          </>
        )}
      </Formik>
    </div>
        </>
    )
}

export default PoResetPassword;