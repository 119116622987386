import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./addAdmin.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Parse from "parse";
import { useFormik } from "formik";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { getAdminDetails, adminData } from "./addAdminSlice";
import { toast } from "react-toastify";
export default function AddAdmin(){
    let { id } = useParams();
    id = id.substring(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const email = useSelector(adminData);
    const [adminEmail,setAdminEmail] = useState();
    let data = {
        objectId:id,
     email:email ,
     firstname:'',
     lastname:'',
     password:'',
     status:'active'
    };
    const formik = useFormik({
      initialValues: data,
      enableReinitialize:true,
      onSubmit: async (values) => {
        const query = new Parse.Query("Admin_User");
        const object = await query.get(values.objectId);
        object.set("firstname",values.firstname);
        object.set("lastname",values.lastname);
        object.set("password",values.password);
        object.set("status",values.status);
        
        try {
            const token = localStorage.getItem("emv-token");
          const response = await object.save();
          const logs = new Parse.Object("Admin_Activity_Log")
          logs.set("adminId",token)
          logs.set("type","Admin Accepted")
          await logs.save();
          toast.success(`Admin Added Successfully`);
          navigate('/places')
        } catch (error) {
          toast.success(`Error in update-----. `);
        }
      },
    });
    useEffect(() => {
     dispatch(getAdminDetails({ id:id }));
    }, []);
  
  
    return (
      <>
          <div>
            <span className="dot">
              <img
                src={`${process.env.PUBLIC_URL}/assets/LoginImage.png`}
                width="600px"
                height="600px"
                style={{ zIndex: "99999", position: "relative" }}
                alt="logo"
              />
            </span>
            <Container>
              <Form onSubmit={formik.handleSubmit}>
                <Row className="align-items-strech ">
                  <Col xs={12} md={12} className="mt-5 d-flex justify-content-center">
                    <div className="edit-form-div" style={{width:"34%"}}>
                      <div className="mb-4">
                        <div className="heading">
                          Add Admin Details
                        </div>
                        </div>
                      <Form.Group className="mb-4" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                           disabled
                          type="text"
                          placeholder="Enter Email"
                          value={formik.values.email}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="firstname">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter First Name"
                          value={formik.values.firstname || ''}
                          onChange={formik.handleChange}
                          autocomplete="off"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="lastname">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last Name"
                          value={formik.values.lastname || ''}
                          onChange={formik.handleChange}
                          autocomplete="off"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Password"
                          value={formik.values.password || ''}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                      <div className="text-center mt-3">
                      <Button
                        type="submit"
                        className="edit-form-btn m-0"
                        variant="success"
                      >
                        Submit
                      </Button>
                    </div>
                    </div>
                  </Col>
                 
                </Row>
              </Form>
            </Container>
          </div>
     
         </>
    );
}