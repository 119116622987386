import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
const initialState = {
  admiPlaceOwnerData: [],
  ownerPlaceName: [],
  placesData: [],
  searchLoading: false,
  totalCount: 0,
  status: false,
  message: ""
};
export const getOwnerShipDetailAsync = createAsyncThunk(
  "/get-owner-detail",
  async (id) => {
    try {
        var objectId = id;
      const query = new Parse.Query("Place_Owner")
      query.equalTo('objectId', objectId);
      const response = await query.find();
      return response
    } catch (e) {
      console.log(e);
    }
  }
);

export const getOwnerShipUserPlaceDetailAsync = createAsyncThunk(
    "/get-owner-place-detail",
    async (id) => {
      try {
          var placeOwnerId = id;
        const query = new Parse.Query("PlaceOwnerShipRequest")
        query.equalTo('placeOwnerId', placeOwnerId);
        const response = await query.find();
        return response
      } catch (e) {
        console.log(e);
      }
    }
  );

  export const postTransferOwnerAsync = createAsyncThunk(
    "/post-owner-transfer",
    async ({id, placeOwnerId}) => {
      var placeId = id
      try {
        const objNew = new Parse.Query("Places")
        objNew.equalTo('objectId', placeId)
        const respo = await objNew.find();
        respo[0].set('ownerId', placeOwnerId)
        const respon = await respo[0].save()
        const query = new Parse.Query("PlaceOwnerShipRequest")
        query.equalTo('placeOwnerId', placeOwnerId);
        const response = await query.find();
        console.log("response", response)
        if(response){
          // const newObject = new Parse.Object("PlaceOwnerShipRequest");
          response[0].set("placeId", placeId);
          const res = await response[0].save()
          return res
        }
      } catch (e) {
        console.log(e);
      }
    }
  );

  export const getPlacesListAsync = createAsyncThunk(
    "/get-places-list",
    async (searchKey) => {
      try {
        const query = new Parse.Query("Places")
        query.limit(1000);
        query.equalTo("ownerId", null)
        query.notEqualTo("disable", true)
        if (searchKey != "") {
            query.contains("placeName", searchKey)
            query.matches("placeName", searchKey, 'i')
          }
        const response = await query.find();
        return response
      } catch (e) {
        console.log(e);
      }
    }
  );

  export const sendTransferEmailAsync = createAsyncThunk(
    "/send-email",
    async ({placeOwnerId, id, placeName}) => {
      var adminId = localStorage.getItem("emv-token")
      const params1 = {"placeOwnerId": placeOwnerId, "placeId": id, "placeName": placeName}
      try {
        const response = await Parse.Cloud.run('emailOwnershipTransfer', params1)
        if(response == "Ownership Transferred successfully!"){
          const newObject = new Parse.Object("Admin_Activity_Log");
            newObject.set("placeId", id);
            newObject.set("placeOwnerId", placeOwnerId);
            newObject.set("placeName", placeName);
            newObject.set("adminId", adminId);
            newObject.set("type", "Ownership Transfered");
            await newObject.save();
        }
        return response
      } catch (e) {
        console.log(e);
      }
    }
  );

const adminPlaceOwnerDetailSlice = createSlice({
  name: "placeownerdetail",
  initialState: initialState,

  reducers: {
    resetStatus: (state, action) => {
      state.status = false
    },
    resetMessage: (state, action) => {
      state.message = ""
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOwnerShipDetailAsync.fulfilled, (state, action) => {
        const allData = action.payload;
        let newData = [];
        allData.forEach((item) => {

          newData.push(
            Object.assign({ ...item.attributes })
          );
        });
        state.admiPlaceOwnerData = newData;
    })
    .addCase(getOwnerShipUserPlaceDetailAsync.fulfilled, (state, action) => {
        const allData = action.payload;
        let newData = [];
        allData.forEach((item) => {
          newData.push(
            Object.assign({ ...item.attributes })
          );
        });
        state.ownerPlaceName = newData;
    }) 
    .addCase(getPlacesListAsync.pending, (state) => {state.searchLoading = true; })
    .addCase(getPlacesListAsync.fulfilled, (state, action) => {
        const allData = action.payload;
        let newData = [];
        allData.forEach((item) => {
          // if( typeof item.attributes.images === "object"){
          //   state.imageGalaryArray.push(item.attributes.images._url);
          // }

          newData.push(
            Object.assign({ ...item.attributes }, { ObjectId: item.id })
          );
        });
        state.placesData = newData;
        state.searchLoading = false
    })
    .addCase(postTransferOwnerAsync.pending, (state) => {state.status = false})
    .addCase(postTransferOwnerAsync.fulfilled, (state, action) => {
      state.status = true
    })
    .addCase(sendTransferEmailAsync.pending, (state) => {state.status = false})
    .addCase(sendTransferEmailAsync.fulfilled, (state, action) => {
      state.message = action.payload
    })
  },
});

export const { resetStatus, resetMessage } = adminPlaceOwnerDetailSlice.actions

export const selectAdminPLaceOwnerData = (state) => state.placeownerdetail.admiPlaceOwnerData;
export const selectAdminPLaceOwnerPLaceNameData = (state) => state.placeownerdetail.ownerPlaceName;
export const selectPlacesData = (state) => state.placeownerdetail.placesData;
  export const selectSearchLoading = (state) => state.placeownerdetail.searchLoading;
  export const selectStatus = (state) => state.placeownerdetail.status
  export const selectMessage = (state) => state.placeownerdetail.message

export default adminPlaceOwnerDetailSlice.reducer;
