import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";

const initialState = {
  status: "",
  loading: false,
  loggedIn: false,
  token: "",
  loginError: "",
  loginSuccess: ""
};
export const ownerLoginAsync = createAsyncThunk(
  "/owner-login",
  async ({ email, password }) => {
    const query = new Parse.Query("Place_Owner");

    query.equalTo('email', email);
    query.equalTo('password', password);
    try{
    const response = await query.find();
    if(response && response.length && response[0].id){
    localStorage.setItem("owner-emv-token", response[0].id)
    localStorage.setItem("status", true)
    return response;
    } else {
      return "unauthorized"
    }
    } catch(err){
      console.log(err)
    }
  }
);
const ownerLoginSlice = createSlice({
  name: "ownerLogin",
  initialState: initialState,

  reducers: {
      clearLoginMsg: (state, action) => {
        state.loginError = ""
        state.loginSuccess = ""
      }
  },
  extraReducers: (builder) => {

    builder.addCase(ownerLoginAsync.pending, (state, action) => {
      state.loggedIn = false;
      state.loading = true
    })
    .addCase(ownerLoginAsync.fulfilled, (state, action) => {
    console.log("action.payload.attributes.adminId" , action.payload)
    if(action.payload === "unauthorized"){
      state.loginError = action.payload
    }
    if(action.payload[0].id){
      state.loginSuccess = "login successfully"
    }
      state.loading = false;
      state.loggedIn = true;
    })
    // .addCase(ownerLoginAsync.rejected, (state, action) => {
    //   console.log("acti", action.payload)
    //   if(action.payload === undefined){
    //     state.loginError = 'unauthorized'
    //   }
    //   state.loggedIn = false;
    //   state.loading = false
    // })
  }
})

export const { clearLoginMsg } = ownerLoginSlice.actions

export const isUserLoggedIn = state => state.ownerLogin.loggedIn
export const isLoginError = state => state.ownerLogin.loginError
export const isLoginSuccess = state => state.ownerLogin.loginSuccess
export default ownerLoginSlice.reducer
