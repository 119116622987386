import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
const initialState = {
    message: ""
  };
export const getAdminDetails = createAsyncThunk(
    "/admin-detail",
    async (id) => {
      try {
        const query = new Parse.Query("Admin_User");

        query.equalTo('objectId', id.id);
        const response = await query.find();
        return response
      } catch (e) {
        console.log(e);
      }
    }
  );

const addAdminDetailsSlice = createSlice({
    name: "addAdminDetails",
    initialState: initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(getAdminDetails.pending, (state) => {state.status = false})
      .addCase(getAdminDetails.fulfilled, (state,action) => {
        const allData = action.payload;
        let newData = [];
        allData.forEach((item) => {
          newData.push(
            Object.assign({ ...item.attributes })
          );
        });
        const data = newData[0].email
        state.adminData = data;
      })
    }
  
})

export const adminData = (state) => state.addAdminDetails.adminData;

export default addAdminDetailsSlice.reducer;