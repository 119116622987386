import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
import { EmojiIcons } from "../../constant";

const initialState = {
    isLoading: false,
    placeDetail: [],
    gallery: [],
    imageGalaryIndex: null,
    currentIndex: 1,
}

export const placeDetailAsync = createAsyncThunk(
    "/place-detail",
    async (id) => {
        var objectId = id 
      try {
        const query = new Parse.Query("Places");
        query.equalTo("objectId", objectId);
        const response = await query.find();
        return response;
      } catch (error) {
        console.error("Error while creating records: ", error);
      }
      }
);

export const fetchGalleryAsync = createAsyncThunk(
    "/fetch-gallery",
    async ({placeId}) => {
        const query = new Parse.Query("Places_Gallery");
        query.equalTo('placeId', placeId);
        try {
          const response = await query.find();
          return response;
        } catch (error) {
          console.error("Error while get records: ", error);
        }
    }
  );

const detailSlice = createSlice({
    name: "details",
    initialState: initialState,
    reducers: {
        updateIndex(state, action) {
            if (state.currentIndex <= state.placeDetail.length) {
              state.currentIndex = action.payload;
            }
          },
          incrementIndex(state, action) {
            if (state.currentIndex < state.placeDetail.length - 1) {
              state.currentIndex = state.currentIndex + 1;
            }
          },
          decrementIndex(state, action) {
            if (state.currentIndex > 0) {
              state.currentIndex = state.currentIndex - 1;
            }
          },
        setImageGalaryIndex(state, action) {
            state.imageGalaryIndex = action.payload;
          },
          incrementImageGalaryIndex(state, action) {
            if (state.imageGalaryIndex !== null) state.imageGalaryIndex += 1;
          },
          decrementImageGalaryIndex(state, action) {
            if (state.imageGalaryIndex !== null && state.imageGalaryIndex != 0)
              state.imageGalaryIndex -= 1;
          },
    },
    extraReducers: (builder) => {
      builder
        .addCase(placeDetailAsync.fulfilled, (state, action) => {
            const allData = action.payload;
            let newData = [];            
            allData.forEach((item) => {
              item.attributes.icons && item.attributes.icons.map((t) => {
                let foodItems = EmojiIcons.foodItemsList.find((fi) => fi.name === t);
                let activityItems = EmojiIcons.activityItemsList.find((ai) => ai.name === t);
                if(foodItems == undefined && activityItems == undefined){
                   const index = item.attributes.icons.indexOf(t);
                   if(index > -1) {
                    item.attributes.icons.splice(index,1)
                   }
                }
              })
              newData.push(
                Object.assign({ ...item.attributes }, { objectId: item.id })
              );
            });
            state.placeDetail = newData[0];
        })
        .addCase(fetchGalleryAsync.fulfilled, (state, action) => {
            const allData = action.payload;
            let newData = [];
            allData.forEach((item) => {
              newData.push(
                Object.assign({ ...item.attributes }, { objectId: item.id })
              );
            });
            state.gallery = newData;
        })
    },
  });

  export const { updateIndex, incrementIndex, decrementIndex, setImageGalaryIndex, incrementImageGalaryIndex, decrementImageGalaryIndex} = detailSlice.actions

  export const selectPlaceDetail = (state) => state.details.placeDetail
  export const selectGallery = (state) => state.details.gallery
  export const getCurrentIndex = (state) => {
    return state.details.currentIndex;
  };
  export const getGalleyIndex = (state) => {
    return state.details.imageGalaryIndex;
  };
  export const getGalleryImageWithId = (i) => (state) => {
    const data = state.details.gallery.filter((item, index) => index == i);
    return data[0].galleryImage._url;
  };

  export default detailSlice.reducer;