import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import Parse from "parse";
import { EmojiIcons } from '../../constant'

const initialState = {
    placeEditData: [],
    updateResMsg: "",
    status: false,
};

export const getPlaceEditData = createAsyncThunk("/po-edit", async (id) => {
    var ownerId = id
        const query = new Parse.Query("Places");
        query.equalTo('ownerId', ownerId);
  try {
    const response = await query.find();
    return response;
  } catch (err) {
    console.log(err);
  }
});

export const updatePlaceAsync = createAsyncThunk(
  "/owner-place-update",
  async (values) => {
    try {
        const query = new Parse.Query("Places");
      const object = await query.get(values.objectId);
      for (const key in values) {
        object.set(key, values[key]);
      }
        const response = await object.save();
        return response
      } catch (error) {
        console.log(error)
      }
    
  });
  
  export const removeGalleryImageAsync = createAsyncThunk(
    "/remove-gallery-image",
    async ({id}) => {
      var objectId = id
      try {
        const query = new Parse.Object("Places_Gallery");
        query.set('objectId', objectId);
        const response = await query.destroy();
        return response
        } catch (error) {
          console.log(error)
        }
      
    });

const poPlaceEditSlice = createSlice({
  name: "poplaceedit",
  initialState: initialState,

  reducers: {
    resetStatus: (state, action) => {
      state.status = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlaceEditData.pending, (state, action) => {})
      .addCase(getPlaceEditData.fulfilled, (state, action) => {
        const allData = action.payload;
        let newData = [];
        allData.forEach((item) => {
          item.attributes.icons.map((t) => {
            let foodItems = EmojiIcons.foodItemsList.find((fi) => fi.name === t);
            let activityItems = EmojiIcons.activityItemsList.find((ai) => ai.name === t);
            if(foodItems == undefined && activityItems == undefined){
               const index = item.attributes.icons.indexOf(t);
               if(index > -1) {
                item.attributes.icons.splice(index,1)
               }
            }
          })
          newData.push(
            Object.assign({ ...item.attributes }, { objectId: item.id })
          );
        });
        state.placeEditData = newData[0];
      })
      .addCase(updatePlaceAsync.pending, (state, action) => { state.updateResMsg = ""})
      .addCase(updatePlaceAsync.fulfilled, (state, action) => {
        state.updateResMsg = "Place updated successfully"
      })
      .addCase(removeGalleryImageAsync.pending, (state, action) => { state.updateResMsg = ""; state.status = false})
      .addCase(removeGalleryImageAsync.fulfilled, (state, action) => {
        state.status = true
      })
      
  },
});

export const { resetStatus } = poPlaceEditSlice.actions;

export const selectPlaceEditData = (state) => state.poplaceedit.placeEditData
export const selectUpdateResMsg = (state) => state.poplaceedit.updateResMsg
export const selectStatus = (state) => state.poplaceedit.status

export default poPlaceEditSlice.reducer;
