import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import Parse from "parse";

const initialState = {
  status: "",
  loading: false,
  loggedIn: false,
  token: "",
  userData: [],
  mediaData: []
};

export const addCodeInstaAsync = createAsyncThunk("/insta", async (code) => {
  const query = new Parse.Query("Place_Owner");

  const id = localStorage.getItem("owner-emv-token");
  const object = await query.get(id);
  object.set("authToken", code);
  try {
    const response = await object.save();
    return response;
  } catch (err) {
    console.log(err);
  }
});

export const postInstaAsync = createAsyncThunk("/insta-post", async (data) => {
  const owner = localStorage.getItem("owner-emv-token")
  const data1 = {...data, ownerId: owner}
  try {
    const response = await Parse.Cloud.run("instaAccessToken", data1);
    // if (response) {
    //   const query = new Parse.Query("Place_Owner");
    //   const id = localStorage.getItem("owner-emv-token");
    //   const object = await query.get(id);
    //   object.set("accessToken", JSON.stringify(response));
    //   const res = await object.save();
    //   return res
    // }
    return response;
  } catch (err) {
    console.log(err);
  }
});

const instaAuthSlice = createSlice({
  name: "insta",
  initialState: initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCodeInstaAsync.fulfilled, (state, action) => {
        // console.log("action", action)
      })
      .addCase(postInstaAsync.fulfilled, (state, action) => {
        // const data = 
        // console.log("action", action.payload[0].JSON.Parse("Media"))
        // const data = JSON.parse(Object.values(action.payload[2]))
        // console.log(data)
        state.token = action.payload[0].accessToken
        state.mediaData = JSON.parse(Object.values(action.payload[1]))
        state.userData = JSON.parse(Object.values(action.payload[2]))
      });
  },
});
  export const selectUserData = state => state.insta.userData
  export const selectMediaData = state => state.insta.mediaData
  export const selectToken = state => state.insta.token
export default instaAuthSlice.reducer;
