import { Row, Col, Card, Form, Button } from "react-bootstrap"
import { useEffect } from "react";
import { useFormik } from "formik";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import './notification.scss'
import Parse from "parse";
import moment from "moment";
import { toast } from "react-toastify";

const validate = values => {
    const errors = {};
    if (!values.title) { errors.title = 'Required'; }
    if (!values.message) { errors.message = 'Required'; }
    if (!values.subscriber) { errors.subscriber = 'Required'; }
    if (!values.schedule) { errors.schedule = 'Required'; }
    return errors;
};


const Notification = () => {

    useEffect(() => {
        const helloFunction = Parse.Cloud.run("scheduleCronJob");
    }, [])

    const formik = useFormik({
        initialValues: { title: "", message: "", subscriber: "", schedule: "", date: "" },
        validate,
        onSubmit: async (values) => {
            if (values.date === "") {
                values.date = new Date()
            }
            const query = new Parse.Object("pushnotifications")
            query.set("title", values.title)
            query.set("message", values.message)
            query.set("subscriber", values.subscriber)
            query.set("schedule", values.schedule)
            query.set("date", values.date)
            query.set("status", false)
            const response = await query.save();
            if(response.id){
                toast.success("data successfully saved")
                formik.resetForm()
            } else {
                toast.error("failed")
            }
        },
    });

    return (
        <>
            <div className="notification-main">
                <Row className="notification-row">
                    <Col md={6} xs={12}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={formik.handleSubmit}>
                                    <h4 className="text-center m-3">Push Notification</h4>
                                    <Form.Group className="mb-4" controlId="title">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            style={{ height: "40px" }}
                                            type="text"
                                            maxLength={30}
                                            placeholder="Enter title"
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.title ? <Form.Label className="mb-0 text-danger">{formik.touched.title && formik.errors.title}</Form.Label>: null}
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="message">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            maxLength={500}
                                            type="text"
                                            style={{ borderRadius: "25px" }}
                                            placeholder="Enter message"
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.message ? <Form.Label className="mb-0 text-danger">{formik.touched.message && formik.errors.message}</Form.Label>: null}
                                    </Form.Group>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="subscriber">
                                                <Form.Label>Subscriber</Form.Label>
                                                <Form.Select
                                                    style={{ height: "40px", borderRadius: "25px" }}
                                                    value={formik.values.subscriber}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option value="" hidden>Select subscriber</option>
                                                    <option value="All">All</option>
                                                    <option value="Premium">Premium</option>
                                                    <option value="Non Premium">Non Premium</option>
                                                </Form.Select>
                                        {formik.errors.subscriber ? <Form.Label className="mb-0 text-danger">{formik.touched.subscriber && formik.errors.subscriber}</Form.Label>: null}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="schedule">
                                                <Form.Label>Schedule</Form.Label>
                                                <Form.Select
                                                    style={{ height: "40px", borderRadius: "25px" }}
                                                    value={formik.values.schedule}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option value="" hidden>Select schedule</option>
                                                    <option value="Immediate">Immediate</option>
                                                    <option value="Schedule">Schedule</option>
                                                </Form.Select>
                                        {formik.errors.schedule ? <Form.Label className="mb-0 text-danger">{formik.touched.schedule && formik.errors.schedule}</Form.Label>: null}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {formik.values.schedule === "Schedule" && <Row>
                                        <Col md={6} xs={12}>
                                            <Form.Group className="mb-3" controlId="date">
                                                <div className="date-time-div">
                                                    <Form.Label>Date</Form.Label>
                                                    <DatePicker
                                                        minDate={moment().toDate()}
                                                        selected={formik.values.date || new Date()}
                                                        onChange={(date) => formik.setFieldValue('date', new Date(date))}
                                                        showTimeSelect
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12}></Col>
                                    </Row>}
                                    <div className="text-center">
                                        <Button className="notification-btn" type="submit">Submit</Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xs={12}></Col>
                </Row>
            </div>
        </>
    )
}

export default Notification