import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export const Loading = () => {
      const navigate = useNavigate()
      const adminToken = localStorage.getItem('emv-token')
      const poToken = localStorage.getItem('place-owner-token')
      useEffect(() => {
            if(adminToken != null){
               navigate('/places')
            }
            if(poToken != null){
              navigate('/business/home')    
            }
            if(adminToken === null || adminToken === undefined && poToken === null || poToken === undefined){
                  navigate('/business/signin')  
            }
      }, [adminToken, poToken])
    return (
    <><div className="loader">
          <div className="fp-container">
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
        </div></>)
}