import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";

const initialState = {
    isLoading: false
}

export const postActivityAsync = createAsyncThunk(
    "/activity",
    async ({data, values}) => {
      const newObject = new Parse.Object("Admin_Activity_Log");    
      newObject.set("placeId", values.objectId);
      newObject.set("adminId", localStorage.getItem('emv-token'));
      newObject.set("oldData", data);
      newObject.set("updatedData", values);
      newObject.set("placeName", values.placeName);
      newObject.set("type", "Place Edited");
      try {
        const response = await newObject.save();
        return response;
      } catch (error) {
        console.error("Error while creating records: ", error);
      }
      }
);

const editDetailSlice = createSlice({
    name: "editDetails",
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(postActivityAsync.fulfilled, (state, action) => {
          console.log("action", action.payload)
        })
    },
  });

  export default editDetailSlice.reducer;