import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Modal, Tabs, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./editDetails.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getRestaurantWithId,
  fetchGallaryImages,
} from "../../store/slices/Slice";
import { postActivityAsync } from './EditDetailSlice'
import Parse from "parse";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { HiUpload } from "react-icons/hi";
import StarsRating from "react-star-rate";
import { placeDetailAsync, selectPlaceDetail } from '../admin-place-detail/DetailsSlice'
import { getInstaUserData, selectGalleryData, selectMediaData, getGallerryDataAsync } from '../po-home/PlaceOwnerHomeSlice'
import { removeGalleryImageAsync } from '../po-place-edit/PoPlaceEditSlice'
import { EmojiIcons } from "../../constant";


export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export default function EditDetails() {
  let { id } = useParams();
  const restaurantData = useSelector(selectPlaceDetail)
  // const message = useSelector(selectUpdateResMsg);
  const galleryData = useSelector(selectGalleryData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [galleryPopup, setGalleryPopup] = useState(false);
  const [removeImageModal, setRemoveImageModal] = useState(false);
  const [removeImage, setRemoveImage] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(placeDetailAsync(id))
    dispatch(getGallerryDataAsync(id))
  }, [id])

  let data = {
    objectId: restaurantData.ObjectId || restaurantData.objectId,
    Address: restaurantData.Address,
    placeName: restaurantData.placeName,
    placeType: restaurantData.placeType,
    description: restaurantData.description,
    ratings: parseFloat(restaurantData.ratings),
    phone: restaurantData.phone,
    Instagram: restaurantData.Instagram,
    Monday: restaurantData.Monday,
    Tues: restaurantData.Tues,
    Wed: restaurantData.Wed,
    Thurs: restaurantData.Thurs,
    Fri: restaurantData.Fri,
    Sat: restaurantData.Sat,
    Sun: restaurantData.Sun,
    images: restaurantData.images,
    icons: restaurantData.icons,
    petFriendly: restaurantData.petFriendly,
    disable: restaurantData.disable
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.ratings) values.ratings = values.ratings.toString();
      if (values.phone) values.phone = parseInt(values.phone)
      const query = new Parse.Query("Places");
      const object = await query.get(values.objectId);
      for (const key in values) {
        object.set(key, values[key]);
      }
      try {
        const response = await object.save();
        toast.success(`Update success!! ${response.get("ratings")}. `);
        navigate('/places')
      } catch (error) {
        toast.success(`Error in update-----. `);
      }
      dispatch(postActivityAsync({ data, values }))
    },
  });

  async function handleChange(e) {
    try {
      e.preventDefault();
      const newGalaryItem = new Parse.Object("Places_Gallery");
      var parseFile = new Parse.File("image-name", e.target.files[0]);
      newGalaryItem.set("placeId", restaurantData.objectId);
      newGalaryItem.set("galleryImage", parseFile);
      const result = await newGalaryItem.save();
      if (result !== undefined || result !== null) {
        dispatch(getGallerryDataAsync({ id: restaurantData.objectId }));
        toast.success("Image uploaded Successfully.");
      }
    } catch (error) {
      console.log("error-----", error);
    }
  }

  const handleRatings = (e) => {
    formik.setFieldValue("ratings", e);
  };

  const handleImageChange = (e) => {
    const parseFile = new Parse.File('image-name', e.target.files[0]);
    formik.setFieldValue('images', parseFile)
  }

  const handleEmojis = (e) => {
    let tag = [];
    if (formik.values.icons.includes(e)) {
      return;
    } else {
      tag = [...formik.values.icons, e];
    }
    if (tag.length > 3) {
      return;
      // tag.splice(0, 1)
    }

    formik.setFieldValue("icons", tag);
  };

  const handleRemove = (e) => {
    let tag = formik.values.icons.filter((t) => t !== e);
    formik.setFieldValue("icons", tag);
  };

  const handleCloseGalleryPopup = () => {
    setGalleryPopup(false);
  };
  const handleOpenGalleryPopup = async () => {
    await dispatch(getGallerryDataAsync({ id: restaurantData.objectId }));
    setGalleryPopup(true);
  };

  const handleRemoveImage = (e) => {
    setRemoveImageModal(true);
    setRemoveImage(e.objectId);
  };

  const handleRemoveImageConfirm = async () => {
    await dispatch(removeGalleryImageAsync({ id: removeImage }));
    await dispatch(getGallerryDataAsync({ id: restaurantData.objectId }));
  };

  const handleRemoveImageClose = () => {
    setRemoveImageModal(false);
  };

  const handlePetFriendly = () => {
    formik.setFieldValue("petFriendly", !formik.values.petFriendly)
  }

  const handlePlaceHide = () => {
    formik.setFieldValue("disable", !formik.values.disable)
  }
  const getIcons = (name) => {
    let foodItems = EmojiIcons.foodItemsList.find((fi) => fi.name === name);
    let activityItems = EmojiIcons.activityItemsList.find((ai) => ai.name === name);
    if(foodItems) return foodItems.image;
    if(activityItems) return activityItems.image;
  }

  return (
    <>
      <div>
        <span className="dot">
          <img
            src={`${process.env.PUBLIC_URL}/assets/LoginImage.png`}
            width="600px"
            height="600px"
            style={{ zIndex: "99999", position: "relative" }}
            alt="logo"
          />
        </span>
        <Container fluid>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="align-items-strech mb-5">
              <Col md={1} className="arrow mt-4">
                <IoArrowBackCircleOutline
                  role="button"
                  onClick={() => {
                    navigate(`/places/detail/${id}`);
                  }}
                />
              </Col>
              <Col xs={12} md={5} className="mt-5">
                <div className="edit-form-div">
                  <div className="mb-4">
                    <div className="heading">
                      You are currently editing :{" "}
                    </div>
                    <div className="heading">{formik.values.placeName}</div>
                  </div>
                  <Form.Group className="mb-4" controlId="placeName">
                    <Form.Label>Place Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter place name"
                      value={formik.values.placeName}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="placeType">
                    <Form.Label>Place Type</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter place type"
                      value={formik.values.placeType}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="Address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address"
                      value={formik.values.Address}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      style={{ borderRadius: "25px" }}
                      type="text"
                      placeholder="Enter description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="Instagram">
                    <Form.Label>Instagram</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter instagram"
                      value={formik.values.Instagram}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <Form.Group className="mb-4">
                      <div className="d-flex">
                        <Form.Label>Emojis</Form.Label>
                        <div className="emoji-img">
                          <img
                            role="button"
                            onClick={handleShow}
                            height="100%"
                            src={`${process.env.PUBLIC_URL}/assets/pluse.png`}
                            alt="logo"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        {formik.values.icons && formik.values.icons.map((t) => (
                          getIcons(t) != undefined  ? <div className="emoji-back">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/Emojis/${getIcons(t)}`}
                              width="50px"
                              height="50px"
                              alt="logo"
                            />
                          </div> : ''
                        ))}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="ratings">
                      <div className="edit-form-ratings">
                        <Form.Label>
                          Ratings: {formik.values.ratings}
                        </Form.Label>
                        <StarsRating
                          value={formik.values.ratings || 0}
                          onChange={(e) => handleRatings(e)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={5} className="mt-5">
                <div className="edit-form-div">
                  <Form.Group className="mb-4" controlId="Monday">
                    <Form.Label>Monday</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Day time"
                      value={formik.values.Monday}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="Tues">
                    <Form.Label>Tuesday</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Day time"
                      value={formik.values.Tues}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="Wed">
                    <Form.Label>Wednesday</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Day time"
                      value={formik.values.Wed}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="Thurs">
                    <Form.Label>Thursday</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Day time"
                      value={formik.values.Thurs}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="Fri">
                    <Form.Label>Friday</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Day time"
                      value={formik.values.Fri}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="Sat">
                    <Form.Label>Saturday</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Day time"
                      value={formik.values.Sat}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="Sun">
                    <Form.Label>Sunday</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Day time"
                      value={formik.values.Sun}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div>
                      <Form.Label className='d-flex' role="button">Upload Cover Image
                        <Form.Group controlId="images">
                          <Form.Control type="file" name='images' accept="image/*" placeholder="Enter Day time" onChange={handleImageChange} maxLength="1" multiple="multiple" style={{ display: "none" }} />
                        </Form.Group>
                        <span className='upload'><HiUpload /></span>
                      </Form.Label>
                    </div>
                    <div>
                      <Form.Label
                        className="d-flex"
                        role="button"
                        onClick={handleOpenGalleryPopup}
                      >
                        Upload Gallery Image
                        <span className="upload">
                          <HiUpload />
                        </span>
                      </Form.Label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <Form.Group className="mb-4" controlId="petFriendly">
                      <Form.Label>Pet Friendly</Form.Label>
                      <Form.Check type={"switch"}>
                        <Form.Check.Input
                          type={"checkbox"}
                          name="petFriendly"
                          checked={formik.values.petFriendly}
                          value={formik.values.petFriendly}
                          onChange={handlePetFriendly}
                        />
                      </Form.Check>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="disable">
                      <Form.Label>Hide Place</Form.Label>
                      <Form.Check type={"switch"}>
                        <Form.Check.Input
                          type={"checkbox"}
                          name="disable"
                          checked={formik.values.disable}
                          value={formik.values.disable}
                          onChange={handlePlaceHide}
                        />
                      </Form.Check>
                    </Form.Group>
                  </div>
                  <div className="text-center mt-3">
                    <Button
                      type="submit"
                      className="edit-form-btn m-0"
                      variant="success"
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <Modal
        className="emojis-modal"
        size="lg"
        show={show}
        centered
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <h3>Food & Drinks</h3>
            <div className="d-flex flex-wrap justify-content-left">
              {EmojiIcons.foodItemsList.map((t, i) => (
                <div className="position-relative m-2" key={i}>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/Emojis/${t.image}`}
                      width="100px"
                      height="100px"
                      alt="logo"
                      value={formik.values.emojis}
                      onClick={(e) => handleEmojis(t.name)}
                    />
                  </div>
                  {/* <div className="text-center">
                    <span>{t.name}</span>
                  </div> */}
                  {formik.values.icons && formik.values.icons.includes(t.name) && (
                    <div className="position-absolute top-0">
                      <img
                        role="button"
                        src={`${process.env.PUBLIC_URL}/assets/clear.png`}
                        onClick={() => handleRemove(t.name)}
                        width="30px"
                        height="30px"
                        alt="logo"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <hr style={{ height: "5px" }} />
          <div>
            <h3>Activitys & Hobbies</h3>
            <div className="d-flex flex-wrap justify-content-left">
              {EmojiIcons.activityItemsList.map((t, i) => (
                <div className="position-relative m-2" key={i}>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/Emojis/${t.image}`}
                      width="100px"
                      height="100px"
                      alt="logo"
                      value={formik.values.emojis}
                      onClick={(e) => handleEmojis(t.name)}
                    />
                  </div>
                  {/* <div className="text-center">
                    <span>{t.name}</span>
                  </div> */}
                  {formik.values.icons && formik.values.icons.includes(t.name) && (
                    <div className="position-absolute top-0">
                      <img
                        role="button"
                        src={`${process.env.PUBLIC_URL}/assets/clear.png`}
                        onClick={() => handleRemove(t.name)}
                        width="30px"
                        height="30px"
                        alt="logo"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        centered
        backdrop="static"
        className="place-gallery-modal-popup"
        show={galleryPopup}
        onHide={handleCloseGalleryPopup}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Image Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4} xs={12} className="mb-4">
              <div className="add-img-div">
                {galleryData && galleryData.length === 7 ? (
                  <h6 className="text-center fw-bold p-1">
                    You have reached the maximum limit of add images.
                  </h6>
                ) : (
                  <Form.Label className="d-flex" role="button">
                    <Form.Group>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        placeholder="Enter Day time"
                        onChange={(e) => handleChange(e)}
                        maxLength="7"
                        multiple="multiple"
                        style={{ display: "none" }}
                      />
                    </Form.Group>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/pluse.png`}
                    />
                  </Form.Label>
                )}
              </div>
            </Col>
            {galleryData &&
              galleryData.length > 0 &&
              galleryData.map((data, i) => (
                <Col className="mb-4 gallery-col" md={4} xs={12} key={i}>
                  <img src={data.galleryImage._url} alt="logo" />
                  <img
                    onClick={() => handleRemoveImage(data)}
                    className="delete-img"
                    src={`${process.env.PUBLIC_URL}/assets/delete.svg`}
                  />
                </Col>
              ))}
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        centered
        className="place-gallery-modal-popup"
        show={removeImageModal}
        onHide={handleRemoveImageClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4
            className="text-center"
            style={{ color: "#1C3879", fontWeight: "bold", lineHeight: "40px" }}
          >
            Are you sure you want to remove image!
          </h4>
          <div className="place-gallery-modal-message">
            <Button
              className="btn-no  me-2"
              variant="primary"
              onClick={handleRemoveImageClose}
            >
              No
            </Button>
            <Button
              className="btn-yes"
              variant="secondary"
              onClick={handleRemoveImageConfirm}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
