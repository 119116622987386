import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";

const initialState = {
  status: "",
  loading: false,
  msg: ""
};
export const poForgotPasswordAsync = createAsyncThunk(
  "/forgot-password",
  async ({email, token}) => {
    const query = new Parse.Query("Place_Owner");

    query.equalTo('email', email);
    const response = await query.find();
    
    if(response && response.length && response[0].id){
      const newObject = new Parse.Query("Place_Owner");
      const owner = await newObject.get(response[0].id)
      owner.set("forgotPasswordToken", token);
      const res = await owner.save();
      let params1 = {"email": email, "token": token}
      const respo = await Parse.Cloud.run('forgotPasswordEmailBusiness', params1)
      return respo
    } else {
       return "either email is not found or not valid"
    }
  }
);

const PlaceOwnerForgotPasswordSlice = createSlice({
  name: "poforgotPassword",
  initialState: initialState,

  reducers: {
    clear: (state, action) => {
        state.msg = ""
    }
  },
  extraReducers: (builder) => {

    builder.addCase(poForgotPasswordAsync.pending, (state, action) => {
        state.loading = true
      })
      .addCase(poForgotPasswordAsync.fulfilled, (state, action) => {
        state.msg = action.payload
        state.loading = true
      })
  }
})

export const {clear} = PlaceOwnerForgotPasswordSlice.actions 
export const isUserLoggedIn = state => state.poforgotPassword.status
export const emailSendMsg = state => state.poforgotPassword.msg
// export const selectToken = state => localStorage.getItem("emv-token")
export default PlaceOwnerForgotPasswordSlice.reducer
