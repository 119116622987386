import { Col, Row, ListGroup } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./adminAuth.scss";
import { useState } from "react";

export default function Protected(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const href = window.location.href;
  const token = localStorage.getItem("emv-token");
  const activeLink = (path) => (href.endsWith(path) ? "active" : "");
  const handleSHow = () => {
    setShow(!show);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/places/signin");
    setShow(false);
  };
  return (
    <>
      {token === null ? (
        <Navigate to="/places/signin" />
      ) : (
        <>
          <div className="mobile-sidebar">
            <img
              role="button"
              src={`${process.env.PUBLIC_URL}/assets/menu.svg`}
              height="30px"
              onClick={handleSHow}
            />
          </div>
          <Row className="main-warap-div d-flex m-0 ">
            <div
              className={
                show === true
                  ? "mobile-open-sidebar web-sidebar"
                  : "web-sidebar"
              }
            >
              <div className="web-sidebar-logo">
                <img
                  role="button"
                  src={`${process.env.PUBLIC_URL}/assets/emvite-logo.svg`}
                  alt="emvita logo"
                />
                <div className="mobile-sidebar-close">
                  <img
                    role="button"
                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                    height="30px"
                    onClick={handleSHow}
                  />
                </div>
              </div>
              <ul>
                <li>
                  <ListGroup.Item
                    as={Link}
                    to="/places"
                    className={activeLink("/places")}
                  >
                    Places
                  </ListGroup.Item>
                </li>
                <li>
                  <ListGroup.Item
                    as={Link}
                    to="/places/add-admin"
                    className={activeLink("/places/add-admin")}
                  >
                    Add Admin
                  </ListGroup.Item>
                </li>
                <li>
                  <ListGroup.Item
                    as={Link}
                    to="/places/foe-waiting-list"
                    className={activeLink("/places/foe-waiting-list")}
                  >
                    Waiting List
                  </ListGroup.Item>
                </li>
                <li>
                  <ListGroup.Item
                    as={Link}
                    to="/places/owner-request-list"
                    className={activeLink("/places/owner-request-list")}
                  >
                    Owner Request List
                  </ListGroup.Item>
                </li>
                <li>
                  <ListGroup.Item
                    as={Link}
                    to="/places/activity-logs"
                    className={activeLink("/places/activity-logs")}
                  >
                    Admin Logs
                  </ListGroup.Item>
                </li>
                <li>
                  <ListGroup.Item
                    as={Link}
                    to="/places/configuration"
                    className={activeLink("/places/configuration")}
                  >
                    Configuration
                  </ListGroup.Item>
                </li>
                <li>
                  <ListGroup.Item
                    as={Link}
                    to="/places/notification"
                    className={activeLink("/places/notification")}
                  >
                    Notification
                  </ListGroup.Item>
                </li>
              </ul>
              <button
                className="web-logout-btn"
                type="button"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
            <Col className="wrap-content">{props.children}</Col>
          </Row>
        </>
      )}
    </>
  );
}
