import { configureStore } from "@reduxjs/toolkit";
// import detailSlice from "../pages/admin-place-detail/DetailsSlice";
// import EditDetails from "../pages/editDetails/EditDetailsSlice";
// import HomeSlice from "../pages/home/HomeSlice";
import EditDetailSlice from "../pages/admin-place-edit/EditDetailSlice";
import loginSlice from "../pages/admin-signin/loginSlice";
import FoodSlice from "./slices/Slice";
import OwnerSignUpSlice from "../pages/po-signup/OwnerSignUpSlice";
import PlaceOwnerLoginSlice from "../pages/po-signin/PlaceOwnerLoginSlice";
import subscribeSlice from "../pages/emviteinvite/FriendsOfEmviteSlice"
import waitingSlice from "../pages/admin-witing-list/WaitingListSlice"
import instaAuthSlice from "../pages/instagramauth/AuthInstagramSlice"
import PlaceOwnerHomeSlice from "../pages/po-home/PlaceOwnerHomeSlice";
import OwnerRequestSlice from "../pages/admin-owner-request/OwnerRequestSlice";
import PlaceOwnerInstaDetailSlice from "../pages/admin-place-owner-insta-detail/PlaceOwnerInstaDetailSlice";
import AdminLogsSlice from "../pages/admin-logs/AdminLogsSlice";
import PoPlaceEditSlice from "../pages/po-place-edit/PoPlaceEditSlice";
import DetailsSlice from "../pages/admin-place-detail/DetailsSlice";

import addAdminOwnerDetailSlice from "../pages/admin-list/AdminListSlice"
import addAdminDetailsSlice from "../pages/admin-add-form/addAdminSlice"
import configurationSlice from "../pages/admin-configuration/ConfigurationSlice"
import ForgotPasswordSlice from "../pages/forgot-password/ForgotPasswordSlice";
import AdminResetPasswordSlice from "../pages/admin-reset-password/AdminResetPasswordSlice";
import PlaceOwnerForgotPasswordSlice from '../pages/po-forgot-password/PoForgotPasswordSlice'
import PlaceOwnerResetPasswordSlice from '../pages/po-reset-password/PoResetPasswordSlice'
export const store = configureStore({
  reducer: {
    food: FoodSlice,
    login: loginSlice,
    // homeslice: HomeSlice,
    editDetails: EditDetailSlice,
    // editDetails:EditDetails,
    register: OwnerSignUpSlice,
    ownerLogin: PlaceOwnerLoginSlice,
    invite: subscribeSlice,
    waiting: waitingSlice,
    insta: instaAuthSlice,
    pohome: PlaceOwnerHomeSlice,
    ownership: OwnerRequestSlice,
    placeownerdetail: PlaceOwnerInstaDetailSlice,
    adminlogs: AdminLogsSlice,
    poplaceedit: PoPlaceEditSlice,
    addAdminOwnerDetail:addAdminOwnerDetailSlice,
    addAdminDetails:addAdminDetailsSlice,
    details: DetailsSlice,
    configuration: configurationSlice,
    forgotPassword: ForgotPasswordSlice,
    adminResetPassword: AdminResetPasswordSlice,
    poforgotPassword: PlaceOwnerForgotPasswordSlice,
    placeOwnerResetPassword: PlaceOwnerResetPasswordSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
