import React, { useEffect, useState } from "react";
import Parse from "parse";
import { Card, Row, Image, Col, Button, Modal, Stack, Form, Badge } from "react-bootstrap";
// import { BsInstagram, BsFacebook } from "react-icons/bs";
import { FaEdit } from 'react-icons/fa'
import StarsRating from "react-star-rate";
import { useSelector, useDispatch } from "react-redux";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import './details.scss'
import { getPlaceActivityViewedDataAsync, getPlaceActivitySuggestedCountAsync, selectViewedCount, selectSuggestedCount  } from '../po-home/PlaceOwnerHomeSlice' 
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ImageGalaryModal from "../../components/Gallery/ImageGalleryModal";
import { placeDetailAsync, selectPlaceDetail, fetchGalleryAsync, selectGallery, getCurrentIndex, getGalleyIndex, setImageGalaryIndex } from './DetailsSlice'
import { EmojiIcons } from "../../constant";


export default function Details(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams()
  const [showModal, setShowModal] = useState(false);
  let index = useSelector(getCurrentIndex);
  const data = useSelector(selectPlaceDetail)
  const galaryImages = useSelector(selectGallery)
  let galleryIndex = useSelector(getGalleyIndex);
  const totalSuggested = useSelector(selectSuggestedCount)
  const totalViewed = useSelector(selectViewedCount)

  // async function handleChange(e) {
  //   try {
  //     e.preventDefault();
  //     const newGalaryItem = new Parse.Object("Places_Gallery");
  //     var parseFile = new Parse.File("image-name", e.target.files[0]);
  //     newGalaryItem.set("placeId", data.ObjectId);
  //     newGalaryItem.set("galleryImage", parseFile);
  //     const result = await newGalaryItem.save();
  //     if (result !== undefined || result !== null) {
  //       dispatch(fetchGalleryAsync({ placeId: id}));
  //       toast.success("Image uploaded Successfully.")
  //     }
  //   } catch (error) {
  //     console.log("error-----", error);
  //   }
  // }
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    dispatch(placeDetailAsync(id))
    dispatch(getPlaceActivitySuggestedCountAsync({ placeId: id }))
    dispatch(getPlaceActivityViewedDataAsync({ placeId: id }))
    dispatch(fetchGalleryAsync({placeId: id}))
  }, [index, id]);

  const handleEdit = () => {
    navigate(`/places/edit/${id}`)
  }

  const getIcons = (name) => {
    let foodItems = EmojiIcons.foodItemsList.find((fi) => fi.name === name);
    let activityItems = EmojiIcons.activityItemsList.find((ai) => ai.name === name);
    if(foodItems) return foodItems.image;
    if(activityItems) return activityItems.image;
  }
  return (
    // <>
    // <Row className="p-4">
    //   <Col md={9}>
    //     <Row className="detail-row">
    //       <Col md={6} xs={12} className="detail-left-col">
    //         <Card>
    //         {data.images && data.images._url && <img className="cover-img" src={data.images._url} />}
    //         </Card>
    //       </Col>
    //       <Col md={6} xs={12} className="detail-right-col">
    //         <div className="d-flex justify-content-between align-items-center">
    //           <h2>{data.placeName}</h2>
    //           <FaEdit role="button" className="edit" size={'30px'} onClick={handleEdit} />
    //           </div>
    //           <div className="d-flex justify-content-between align-items-center">
    //         <div className="emojis">
    //         {data.icons && data.icons.map((t, i) => <img className="icons-emo" key={i} src={`${process.env.PUBLIC_URL}/assets/Emojis/${t}.png`} width="100%" />)}
    //         </div>
    //         <div className="mt-1">
    //         <StarsRating disabled value={parseFloat(data.ratings)} name="ratings" readonly />
    //         </div>
    //         </div>
    //         <div className="fs-2rem mt-3">{data.placeType}</div>
    //         <div className="mt-3">
    //         <Stack gap={0} direction='horizontal' className="availibility">
    //              <Stack gap={0} direction='vertical' className="availibility">
    //                <Stack gap={3} direction='horizontal'><span>Mon:</span> {data.Monday}</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Tues:</span> {data.Tues}</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Wed:</span> {data.Wed}</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Thurs:</span> {data.Thurs}</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Fri:</span> {data.Fri}</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Sat:</span> {data.Sat}</Stack>
    //              </Stack>
    //              <Stack gap={0} direction='vertical' className="availibility">
    //                <Stack gap={3} direction='horizontal'><span>Search:</span>xxxx</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Check-Ins:</span>xxxx</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Followers:</span>xxxx</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Shared:</span>xxxx</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Suggested:</span> {totalSuggested}</Stack>
    //                <Stack gap={3} direction='horizontal'><span>Viewed:</span> {totalViewed}</Stack>
    //              </Stack>
    //            </Stack>
    //         </div>
    //       </Col>
    //       <div className="m-2">
    //       <hr/>
    //       </div>
    //       <Row>
    //         <Col md={12}>
    //         <div className="discription">{data.description}</div>
    //         </Col>
    //       </Row>
    //     </Row>
    //   </Col>
    //   <Col md={3}></Col>
    // </Row>
    // </>
    <div >
      <span className="dot">
        <img
          src={`${process.env.PUBLIC_URL}/assets/LoginImage.png`}
          width="600px"
          height="600px"
          style={{ zIndex: "99999", position: "relative" }}
        />
      </span>
      {data && 
      <Stack gap={4} direction='horizontal' className="emv-place">
        <Stack gap={1} className="arrow">
        <IoArrowBackCircleOutline
              role="button"
              onClick={() => {
                navigate(`/places`);
              }}
            />
        </Stack>
        <Stack gap={3} direction="vertical" className="detail">
          <Stack gap={3} direction="horizontal" className="al-baseline">
            {data.images && data.images._url && <img className="cover-img" src={data.images._url} />}
            <Stack gap={3} direction='vertical' className="fs-2rem">
              <Stack gap={3} direction='horizontal' className="justify-content-between">
                <h2>{data.placeName}</h2>
                <FaEdit role="button" className="edit" size={'30px'} onClick={handleEdit} />
              </Stack>
              <Stack gap={3} direction='horizontal' className="justify-content-between">
                <div className="emojis">
                  {data.icons && data.icons.map((t, i) => getIcons(t) != undefined  ? <img className="icons-emo" key={i} src={`${process.env.PUBLIC_URL}/assets/Emojis/${getIcons(t)}`} width="100%" /> : '')}
                </div>    
              <div className="pet-friendly"><Badge pill>{data.petFriendly === true ? "Pet Friendly" : null}</Badge></div>
                <span className="float-end"> <StarsRating disabled value={parseFloat(data.ratings)} name="ratings" readonly /> </span>
              </Stack>
              <div className="fs-2rem">{data.placeType}</div>
              <hr className="m-1" />
              <Stack gap={0} direction='horizontal' className="availibility">
                <Stack gap={0} direction='vertical' className="availibility">
                  <Stack gap={3} direction='horizontal'><span>Mon:</span> {data.Monday}</Stack>
                  <Stack gap={3} direction='horizontal'><span>Tues:</span> {data.Tues}</Stack>
                  <Stack gap={3} direction='horizontal'><span>Wed:</span> {data.Wed}</Stack>
                  <Stack gap={3} direction='horizontal'><span>Thurs:</span> {data.Thurs}</Stack>
                  <Stack gap={3} direction='horizontal'><span>Fri:</span> {data.Fri}</Stack>
                  <Stack gap={3} direction='horizontal'><span>Sat:</span> {data.Sat}</Stack>
                </Stack>
                <Stack gap={0} direction='vertical' className="availibility">
                  <Stack gap={3} direction='horizontal'><span>Search:</span>xxxx</Stack>
                  <Stack gap={3} direction='horizontal'><span>Check-Ins:</span>xxxx</Stack>
                  <Stack gap={3} direction='horizontal'><span>Followers:</span>xxxx</Stack>
                  <Stack gap={3} direction='horizontal'><span>Shared:</span>xxxx</Stack>
                  <Stack gap={3} direction='horizontal'><span>Suggested:</span> {totalSuggested}</Stack>
                  <Stack gap={3} direction='horizontal'><span>Viewed:</span> {totalViewed}</Stack>
                </Stack>
              </Stack>
              
            </Stack>
          </Stack>
          <hr className="w-100" />
          <div className="discription">
            {data.description}
          </div>
        </Stack>
        <Stack gap={4} direction='vertical' className="gallary col-md-4 mx-auto">
          {galaryImages && galaryImages.length > 0
            && galaryImages.map((item, i) => {
              return <Image key={i} className="upload-img" src={item.galleryImage._url} onClick={() => { handleShow(); dispatch(setImageGalaryIndex(i)) }} />
            })}
        </Stack>
      </Stack>}
      {showModal === true?<ImageGalaryModal galleryIndex={galleryIndex} galaryLength={galaryImages.length} handleClose={handleClose} showModal={showModal}/>:null}
    </div >
  );
}
