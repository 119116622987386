import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";

const initialState = {
  status: "",
  loading: false,
  resetPassMsg: ""
};
export const poResetPasswordAsync = createAsyncThunk(
  "/place-owner-reset-password",
  async ({password, forgotPasswordToken}) => {
    const query = new Parse.Query("Place_Owner");

    query.equalTo('forgotPasswordToken', forgotPasswordToken);
    const response = await query.find();
    if(response && response.length && response[0].id){
      const newObject = new Parse.Query("Place_Owner");
      const owner = await newObject.get(response[0].id)
      owner.set("password", password);
      const res = await owner.save();
      return res
    }
    // return response;
  }
);

const PlaceOwnerResetPasswordSlice = createSlice({
  name: "placeOwnerResetPassword",
  initialState: initialState,

  reducers: {
    // clear: (state, action) => {
    //     state.
    // }
  },
  extraReducers: (builder) => {

    builder.addCase(poResetPasswordAsync.pending, (state, action) => {
        state.loading = true
      })
      .addCase(poResetPasswordAsync.fulfilled, (state, action) => {
        if(action.payload.id != ""){
        state.resetPassMsg = "reset password successfully"
        }
        state.loading = true
      })
  }
})

export const {clearLoginMsg} = PlaceOwnerResetPasswordSlice.actions 
export const isUserLoggedIn = state => state.placeOwnerResetPassword.status
export const resetPasswordMsg = state => state.placeOwnerResetPassword.resetPassMsg
// export const selectToken = state => localStorage.getItem("emv-token")
export default PlaceOwnerResetPasswordSlice.reducer
