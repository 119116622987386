import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";

const initialState = {
  status: "",
  loading: false,
  resetPassMsg: ""
};
export const adminResetPasswordAsync = createAsyncThunk(
  "/admin-reset-password",
  async ({password, forgotPasswordToken}) => {
    const query = new Parse.Query("Admin_User");

    query.equalTo('forgotPasswordToken', forgotPasswordToken);
    const response = await query.find();
    if(response && response.length && response[0].id){
      const newObject = new Parse.Query("Admin_User");
      const owner = await newObject.get(response[0].id)
      owner.set("password", password);
      const res = await owner.save();
      return res
    }
    // return response;
  }
);

const AdminResetPasswordSlice = createSlice({
  name: "adminResetPassword",
  initialState: initialState,

  reducers: {
    // clear: (state, action) => {
    //     state.
    // }
  },
  extraReducers: (builder) => {

    builder.addCase(adminResetPasswordAsync.pending, (state, action) => {
        state.loading = true
      })
      .addCase(adminResetPasswordAsync.fulfilled, (state, action) => {
        if(action.payload.id != ""){
        state.resetPassMsg = "reset password successfully"
        }
        state.loading = true
      })
  }
})

export const {clearLoginMsg} = AdminResetPasswordSlice.actions 
export const isUserLoggedIn = state => state.adminResetPassword.status
export const resetPasswordMsg = state => state.adminResetPassword.resetPassMsg
// export const selectToken = state => localStorage.getItem("emv-token")
export default AdminResetPasswordSlice.reducer
