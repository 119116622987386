import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
const initialState = {
  waitingData: [],
  totalCount: 0,
  waitingListLoading: false
};
export const getWaitingListAsync = createAsyncThunk(
  "/get-data",
  async ({ page, limit, searchKey }) => {
    const query = new Parse.Query("FriendsOfEmvite");
    let count = await query.count();
    query.limit(limit);
    query.skip(page * limit);
    if (searchKey != "") {
      query.contains("email", searchKey)
      query.matches("email", searchKey, 'i')
    }
    const response = await query.find();
    // return response
    return { response, count };
  }
);

const waitingSlice = createSlice({
    name: "waiting",
    initialState: initialState,
  
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(getWaitingListAsync.pending, (state, action) => { state.waitingListLoading = true})
        .addCase(getWaitingListAsync.fulfilled, (state, action) => {
            const allData = action.payload.response;
            const count = action.payload.count;
            let newData = [];
            allData.forEach((item) => {
    
              newData.push(
                Object.assign({ ...item.attributes }, { ObjectId: item.id })
              );
            });
            state.waitingData = newData
            state.totalCount = count
            state.waitingListLoading = false
        })
    },
  });

  
  export const selectWaitingData = (state) => state.waiting.waitingData;
  export const selectTotalCount = (state) => state.waiting.totalCount;
  export const selectWaitingListLoading = (state) => state.waiting.waitingListLoading;

  export default waitingSlice.reducer;