import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from 'react-bootstrap'
import { useNavigate, Link } from "react-router-dom";
import { forgotPasswordAsync, emailSendMsg, clear } from './ForgotPasswordSlice'
import { useEffect } from "react";
import { toast } from "react-toastify";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const message = useSelector(emailSendMsg)

   useEffect(() => {
    if(message && message == "either email is not found or not valid"){
      toast.error(message)
    } 
    if(message && message == "Email Sent successfully!"){
      toast.success(message)
    } 
    dispatch(clear())
   }, [message])

    const generateToken = () => {
      var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var token = '';
      for(var i = 0; i < 36; i++) {
          token += chars[Math.floor(Math.random() * chars.length)];
      }
      return token;
  }
    return (
        <>
        <div >
      <Formik
        initialValues={{ email: ""}}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
           let token = generateToken()
           await dispatch(forgotPasswordAsync({email: values.email, token}))
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <>
          {localStorage.getItem("emv-token")?navigate("/places"):<>
          <div className="login-main">
          <div className="logo-header">
              <img
                src={`${process.env.PUBLIC_URL}/assets/emvite-logo.svg`}
              />
          </div> 
          <div className="overlay-img">
              <img
                src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
              />
          </div>
          <div className="overlay-shap">
          <img
                src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`}
              /> 
          </div>
            <div className="login-form-main">
                <Form onSubmit={handleSubmit}>
                  
            <div className="login-form-heading">
                <h1>Forgot Password</h1>
              </div>
                  <Form.Group
                    className="mb-3"
                    controlId="email"
                    style={{ position: "relative" }}
                  >
                    <Form.Label className="loginLabel">Email</Form.Label>
                    <div className="login-control">
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      className="emailTextBox"
                      onChange={handleChange}
                      autocomplete="off"
                    />
                    <span className="login-control-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/Login form icons/Account.svg`}
                      height="30px"
                      width="30px"
                      className="emailIcon"
                    />
                    </span>
                    </div>
                    {errors.email !== undefined?<p className="text-danger">{errors.email}</p>:null}
                  </Form.Group>
                  <div className="loginBtnContainer">
                    <Button type="submit" className="loginBtn">
                      Submit
                    </Button>
                  </div>
                </Form>
                <div className="admin-form-bottom-text">
                      <h5>
                        <Link className="login-link" to="/places/signin">
                         Go back to Sign in ?
                        </Link>
                      </h5>
                    </div>
              </div>
            </div>
          </>}
          </>
        )}
      </Formik>
    </div>
        </>
    )
}

export default ForgotPassword;