import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
const initialState = {
    message: "",
    configData: [],
    isLoading: false
  };
export const postConfigAsync = createAsyncThunk(
    "/admin-config",
    async (values) => {
        try {
            const query = new Parse.Query("Admin_Config");
          const object = await query.get(values.objectId);
          for (const key in values) {
            object.set(key, values[key]);
          }
            const response = await object.save();
            return response
          } catch (error) {
            console.log(error)
          }
    }
  );

  export const getConfigAsync = createAsyncThunk(
    "/get-config",
    async () => {
      const query = new Parse.Query("Admin_Config");
      const response = await query.find();
      return response;
    }
  );

const configurationSlice = createSlice({
    name: "configuration",
    initialState: initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(postConfigAsync.pending, (state) => { state.isLoading = true})
      .addCase(postConfigAsync.fulfilled, (state,action) => {
        state.isLoading = false
      })
      .addCase(getConfigAsync.pending, (state) => { state.isLoading = true})
      .addCase(getConfigAsync.fulfilled, (state,action) => {
        const allData = action.payload;
        let newData = [];
        allData.forEach((item) => {
          newData.push(
            Object.assign({ ...item.attributes}, {objectId: item.id})
          );
        });
        state.configData = newData[0];
        state.isLoading = false
      })
    }
  
})

// export const selectConfigMsg = (state) => state.configuration.message;
export const selectConfigData = (state) => state.configuration.configData;
export const selectIsLoading = (state) => state.configuration.isLoading;

export default configurationSlice.reducer;