import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";

const initialState = {
  status: "",
  loading: false,
  loggedIn: false,
  token: "",
  loginError : "",
  loginSuccess: ""

};
export const loginAsync = createAsyncThunk(
  "/login",
  async ({ email, password }) => {
    console.log("email", email)
    const query = new Parse.Query("Admin_User");

    query.equalTo('email', email);
    query.equalTo('password', password);
    const response = await query.find();
    console.log("response", response[0].id)
    if(response[0].id){
      localStorage.setItem("emv-token", response[0].id)
      const newObject = new Parse.Object("Admin_Activity_Log"); 
      newObject.set("adminId", response[0].id);
      newObject.set("type", "Admin Login");
      const res = await newObject.save();
      return res
    }

    // let currentUser = localStorage.getItem("admin-id")
    // console.log("current", currentUser)
    return response;
  }
);
const loginSlice = createSlice({
  name: "login",
  initialState: initialState,

  reducers: {
     clearLoginMsg: (state, action) => {
      state.loginError = ""
      state.loginSuccess = ""
     }
  },
  extraReducers: (builder) => {

    builder.addCase(loginAsync.fulfilled, (state, action) => {
      // const data = action.payload;
      // data.forEach((item) => {
      //   const user = {
      //     email: item.attributes.email,
      //     firstName: item.attributes.firstname,
      //     lastName: item.attributes.lastname
      //   }
      // })
      if(action.payload.attributes.adminId){
        state.loginSuccess = "login successfully"
      }
      state.loading = false;
      state.loggedIn = true;
    }).addCase(loginAsync.pending, (state, action) => {
      state.loggedIn = false;
      state.loading = true
    })
    .addCase(loginAsync.rejected, (state, action) => {
      if(action.payload === undefined){
        state.loginError = 'unauthorized'
      }
      state.loggedIn = false;
      state.loading = false
    })
  }
})

export const {clearLoginMsg} = loginSlice.actions 
export const isUserLoggedIn = state => state.login.loggedIn
export const isLoginError = state => state.login.loginError
export const isLoginSuccess = state => state.login.loginSuccess
// export const selectToken = state => localStorage.getItem("emv-token")
export default loginSlice.reducer
