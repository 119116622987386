import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
const initialState = {
  logData: [],
  totalCount: 0,
  adminLogLoading: false
};
export const getAdminLogsAsync = createAsyncThunk(
  "/get-logs",
  async ({page, limit, searchKey}) => {
    try {
        const query = new Parse.Query("Admin_Activity_Log");
        let count = await query.count();
        query.limit(limit);
        query.skip(page * limit);
        if (searchKey != "") {
          query.contains("placeName", searchKey)
          query.matches("placeName", searchKey, 'i')
        }
      const response = await query.find();
      let newData = [];
      if (response) {
        response.forEach((item) => {
          newData.push(Object.assign({ ...item.attributes }));
        });

        let arr = [];
        for (let i = 0; i < newData.length; i++) {
          arr.push(newData[i].adminId);
        }

        let names = {};
        const query = new Parse.Query("Admin_User");
        query.containedIn("objectId", arr);
        let users = await query.find();
        for (var i = 0; i < users.length; i++) {
          names[users[i].id] = users[i].get("firstname");
        }
        for (let i = 0; i < newData.length; i++) {
          newData[i].adminName = names[newData[i].adminId];
        }
        console.log('newData', newData)
      }
      return {newData, count};
    } catch (e) {
      console.log(e);
    }
  }
);

const adminLogSlice = createSlice({
  name: "adminlogs",
  initialState: initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getAdminLogsAsync.pending, (state, action) => { state.adminLogLoading = true})
    .addCase(getAdminLogsAsync.fulfilled, (state, action) => {
      state.logData = action.payload.newData;
      state.totalCount = action.payload.count
      state.adminLogLoading = false
    });
  },
});

export const selectAdminLogData = (state) => state.adminlogs.logData;
  export const selectTotalCount = (state) => state.adminlogs.totalCount;
  export const selectAdminLogLoading = (state) => state.adminlogs.adminLogLoading;

export default adminLogSlice.reducer;
