import { Row, Col, Container, Form, Button, Modal, Card, Stack, Alert, Table, Badge } from "react-bootstrap";
import "./PlaceOwnerHome.scss";
import { clearMessage } from "../po-signup/OwnerSignUpSlice";
import Parse from "parse";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import StarsRating from "react-star-rate";
import { FaEdit } from 'react-icons/fa'
import { HiUpload } from "react-icons/hi";
import {
  getInstaUserData,
  selectUserData,
  selectMediaData,
  selectToken,
  postOwnerShipAsync,
  selectStatus,
  // selectMessage,
  // resetMessage,
  getOwnerShipAsync,
  resetStatus,
  selectOwnerData,
  getPlaceDataAsync,
  selectPlaceData,
  getPlaceActivityViewedDataAsync,
  selectViewedCount,
  selectSuggestedCount,
  getPlaceActivitySuggestedCountAsync,
  postOwnerDocAsync,
  getOwnerDocDataAsync,
  selectDocData,
  deleteOwnerDoc,
  selectDocStatus,
  resetDocStatus,
  selectIsLoading,
  getPlaceActivityDemographicAsync,
  selectDemograpicData
} from "./PlaceOwnerHomeSlice";
import { placeDetailAsync, selectPlaceDetail, fetchGalleryAsync, selectGallery, getCurrentIndex, getGalleyIndex, setImageGalaryIndex  } from '../admin-place-detail/DetailsSlice'
import PlaceGalleryModal from "./PlaceGalleryModal";
import { selectMessage } from '../po-signup/OwnerSignUpSlice'
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { EmojiIcons } from "../../constant";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from "leaflet";

export const loading = (
  <div className="loader">
        <div className="fp-container">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
      </div>
)


const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const token = useSelector(selectToken);
  const userData = useSelector(selectUserData);
  const mediaData = useSelector(selectMediaData);
  const status = useSelector(selectStatus)
  const message = useSelector(selectMessage)
  const dataOwnerShip = useSelector(selectOwnerData)
  const placeData = useSelector(selectPlaceData);
  const galleryData = useSelector(selectGallery)
  const docData = useSelector(selectDocData)
  const docStatus = useSelector(selectDocStatus)
  let index = useSelector(getCurrentIndex);
  const isLoading = useSelector(selectIsLoading)
  // let data = useSelector(getRestaurantWithId(index));
  const totalViewed = useSelector(selectViewedCount)
  const totalSuggested = useSelector(selectSuggestedCount)
  let galleryIndex = useSelector(getGalleyIndex);
  const demgraphicData = useSelector(selectDemograpicData)
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false)
  const [loginAlertMsg, setLoginAlertMsg] = useState(false)
  const [showGallery, setShowGallery] = useState(false)
  const [showConfirmDeleteDoc, setShowConfirmDeleteDoc] = useState(false)
  const [deleteDocId, setDeleteDocId] = useState("")
  const [showImage, setShowImage] = useState(false)
  const [imageUrl, setImageUrl] = useState("")

  const handleGalleryClose = () => setShowGallery(false);
  const handleGalleryShow = () => setShowGallery(true);

  const handleCloseMessage = () => {setShowMessage(false); dispatch(resetStatus()) };

  const handleClose = () => setShow(false);

  const id = localStorage.getItem("owner-emv-token");

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getInstaUserData(id));
    dispatch(getOwnerShipAsync(id))
    dispatch(getPlaceDataAsync(id))
    dispatch(getOwnerDocDataAsync(id))
  }, []);

  useEffect(() => {
    if(placeData.length > 0){
    dispatch(fetchGalleryAsync({placeId: placeData[0].objectId}))
    dispatch(getPlaceActivityViewedDataAsync({placeId: placeData[0].objectId}))
    dispatch(getPlaceActivitySuggestedCountAsync({placeId: placeData[0].objectId}))
    dispatch(getPlaceActivityDemographicAsync({placeId: placeData[0].objectId}))
    }
  }, [placeData])

  const formik = useFormik({
    initialValues: { placeName: "", placeOwnerShipId: id },
    onSubmit: async (values) => {
      await dispatch(postOwnerShipAsync(values))
      await dispatch(getOwnerShipAsync(id))
    },
  });

  useEffect(() => {
    if(status === true){
      formik.resetForm()
      setShow(false)
    }
  }, [status])

  useEffect(() => {
    if(docStatus === true){
      setShowConfirmDeleteDoc(false)
    }
    dispatch(resetDocStatus())
  }, [docStatus])

  const handleInsta = () => {
    const url = `https://api.instagram.com/oauth/authorize?client_id=197678946191323&redirect_uri=https://emvitedev.b4a.app/auth/instagram.html&scope=user_profile,user_media&response_type=code`;
    window.open(url, "_blank");
  };


  useEffect(() => {
    if(status === true){
      setShowMessage(true)
    }
  }, [status])
  const placeOwnerId = dataOwnerShip.map(d => d.placeOwnerId)

  useEffect(() => {
    if(message && message != ""){
      setAlertMsg(true)
    }
  }, [message])

  useEffect(() => {
    let status = localStorage.getItem("status")
    if(status === "true" && placeData.length > 0){
      setLoginAlertMsg(true)
    }
  }, [placeData])

  const handleCloseAlert = async () => {
    setLoginAlertMsg(false)
    localStorage.removeItem("status")
  }

  const handleNavigateToEdit = (item) => {
    navigate(`/business/place-edit/${item.ownerId}`)
  }

  const handleUpload = async (e) => {
    const parseFile = new Parse.File(e.target.files[0].name, e.target.files[0]);
    await dispatch(postOwnerDocAsync({parseFile, id}))
    await dispatch(getOwnerDocDataAsync(id))
    // formik.setFieldValue("upload", parseFile);
  };
  const handleCloseConfirmDeleteDoc = () => {
    setShowConfirmDeleteDoc(false)
  }

  const handleCloseShowImage = () => {
    setShowImage(false)
  }

  const handleOpenShowImage = (data) => {
    setImageUrl(data)
    setShowImage(true)
  }

  const handleConfirmDateDoc = (item) => {
    setDeleteDocId(item)
    setShowConfirmDeleteDoc(true)
  }
  const handelDeleteDoc = async () => {
    await dispatch(deleteOwnerDoc({deleteDocId: deleteDocId.objectId}))
    await dispatch(getOwnerDocDataAsync(id))
  }

  const getIcons = (name) => {
    let foodItems = EmojiIcons.foodItemsList.find((fi) => fi.name === name);
    let activityItems = EmojiIcons.activityItemsList.find((ai) => ai.name === name);
    if(foodItems) return foodItems.image;
    if(activityItems) return activityItems.image;
  }

  return (
    <>
    {isLoading && loading}
      <div className="po-hom-main">
        <div className="overlay-img">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
          />
        </div>
        <div className="overlay-shap">
          <img src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`} />
        </div>
        {loginAlertMsg && <div className="ps-4 pe-4"><Alert variant="warning" className="mt-4" onClose={handleCloseAlert} dismissible>
        <Alert.Heading>Welcome to Emvite Places!</Alert.Heading>
        <p>We're so glad that you've claimed ownership of your business. Your Emvite Places dashboard is now fully accessible and ready for you to explore. From here, you'll be able to see key metrics and activities related to your business, making it easy for you to stay up-to-date and make informed decisions.
        </p>
        <p>We hope you find this dashboard useful and informative. If you have any questions or need help, please don't hesitate to reach out to us. Ko</p>
      </Alert></div>}     
    {alertMsg && <div className="ps-4 pe-4"> <Alert variant="warning" className="mt-4" onClose={() => setAlertMsg(false)} dismissible>
        <Alert.Heading>Welcome to Emvite Places!</Alert.Heading>
        <p>
        Before you can access all the great features of our platform, you'll need to claim ownership of your business. This is a simple and straightforward process that won't waste any of your time, and all the information we have on your business is already in our system. To claim ownership, simply complete the following steps:
        </p>
        <ul>
          <li>Provide your business name and phone number.</li>
          <li>Sync your business with Instagram to validate your ownership.</li>
          <li>Sometimes, additional validation may be requested to confirm your business information. Uploading a PDF or photo can be used to provide this validation.</li>
        </ul>
        <p>Once you've completed these steps, a member of the Emvite Business Team will grant you access to your Emvite Places dashboard. This dashboard is designed to give you a centralized view of all the activity related to your business, making it easier for you to track its performance and make informed decisions. You'll be able to see key metrics, such as the number of people who may have seen your place or business on Emvite.</p>
        <p>We hope you find this dashboard useful and informative. If you have any questions or need help, please don't hesitate to reach out to us.</p>
      </Alert> </div>}
      <div style={{height: "calc(100vh - 0px)", overflow: "auto", padding: "15px"}}>
          <div className="landing-row">
            <Row className="p-4">   
                  <Col className="custom-map">
                  <MapContainer center={[18.200178, -66.664513]} zoom={9}scrollWheelZoom={false}>
                    <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {demgraphicData && demgraphicData.length > 0 && demgraphicData.map(park => {
                    return  <Marker
                        key={park.objectId}
                        position={[
                          park.fromLocation._latitude,
                          park.fromLocation._longitude
                        ]}
                      />
                      })}
                </MapContainer>
                  </Col>
            </Row>
            <Row className="dash-top-content">
              <Col xs={12} md={6}>
                <div className="conten-left">
                <div className={token === null ? "insta-handle-div landing-col justify-content-center align-items-center" : "insta-handle-div landing-col"}>
                  {token !== undefined && token !== null ? (
                    <div className="insta-profile-div">
                      <div className="user-data">
                        <span>User Name : </span>@{userData.username}
                      </div>
                      <div className="text-center">
                        {placeOwnerId != id && <Button
                          className="insta-handle-btn"
                          type="button"
                          onClick={() => setShow(true)}
                        >
                          Request OwnerShip
                        </Button>}
                      </div>
                      <div className="user-media">
                        {mediaData.map((d, i) => (
                          <div className="user-media-detail" key={i}>
                            <img src={d.media_url} />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Button
                      className="insta-handle-btn"
                      type="button"
                      onClick={handleInsta}
                    >
                      Connect Instagram
                    </Button>
                  )}
                </div>

                <div className="owner-doc mt-4">
                  <Row>
                  <div className="col-4">
                      <Form.Label className="d-flex" role="button">
                        Upload Pdf/Photo
                        <Form.Group controlId="upload">
                          <Form.Control
                            type="file"
                            name="gallaryImages"
                            accept="image/*, application/pdf"
                            placeholder="Enter Day time"
                            onChange={handleUpload}
                            maxLength="1"
                            multiple="multiple"
                            style={{ display: "none" }}
                          />
                        </Form.Group>
                        <span className="upload">
                          <HiUpload />
                        </span>
                      </Form.Label>
                    </div>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}>
                      <div className="admin-table-main no-scroll">
                    <Table bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>File Name</th>
                            <th>CreatedAt</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {docData && docData.map((data, i) => <tr key={i}>
                            <td>{i + 1}</td>
                            {data.file._name.split('_')[1].split('.')[1] === "pdf" ? <td><Link className="pdf-link" role="button" target="_blank" to={data.file._url} locale={false}>{data.file._name.split('_')[1]}</Link></td> : <td onClick={() => handleOpenShowImage(data.file._url)}><span role="button">{data.file._name.split('_')[1]}</span></td>}
                            <td>{data.createdAt.toLocaleString().split(',')[0]}</td>
                            <td className="text-center"><Button type="button" className="deleteBtn me-0" onClick={() => handleConfirmDateDoc(data)}>Delete</Button></td>
                          </tr>)}
                        </tbody>
                      </Table>
                      </div>
                    </Col>
                  </Row>
                </div>
                
                <div className="insta-handle-div landing-col mt-4">
                <Row className="w-100">
                  <div className="mt-4 mb-5">
                    <h2>Performance Detail</h2>
                  </div>
                  <Col className="po-detail-left">
                    <div className="po-detail-left-row">
                      <p>Search</p>
                      <p>xxxx</p>
                    </div>
                    <div className="po-detail-left-row">
                      <p>Check-ins</p>
                      <p>xxxx</p>
                    </div>
                    <div className="po-detail-left-row">
                      <p>Followers</p>
                      <p>xxxx</p>
                    </div>
                    <div className="po-detail-left-row">
                      <p>Shared</p>
                      <p>xxxx</p>
                    </div>
                    <div className="po-detail-left-row">
                      <p>Suggested</p>
                      <p>{totalSuggested}</p>
                    </div>
                    <div className="po-detail-left-row">
                      <p>Viewed</p>
                      <p>{totalViewed}</p>
                    </div>
                  </Col>
                </Row>
                </div>
                {/* <div className="insta-handle-div landing-col mt-4">
                <Row className="w-100">
                  <Col className="po-detail-left">
                  <MapContainer center={[18.200178, -66.664513]} zoom={8}scrollWheelZoom={false}>
                    <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {demgraphicData && demgraphicData.length > 0 && demgraphicData.map(park => {
                    return  <Marker
                        key={park.objectId}
                        position={[
                          park.fromLocation._latitude,
                          park.fromLocation._longitude
                        ]}
                      />
                      })}
                </MapContainer>
                  </Col>
                </Row>
                </div>    */}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="landing-col-right justify-content-center align-items-center p-0">
                {placeData && placeData.length > 0 ? placeData.map( (menu, index) => <Card
                    key={index}
                    className="h-100  "
                  >
                    <div className="card-container">
                      {menu.icons ? <div className="emojiContainer">
                        {menu.icons.map((t, i) => (
                         getIcons(t) != undefined  ? <img
                            key={i}
                            className="icons-img me-2"
                            src={`${process.env.PUBLIC_URL}/assets/Emojis/${getIcons(t)}`}
                            style={{width: "50px", height: "50px"}}
                          /> : ''
                        ))}
                      </div> : ""}
                      <div className="edit-icon-div">           
                <FaEdit role="button" className="edit-img" size={'30px'} onClick={() => handleNavigateToEdit(menu)} />
                      </div>
                      <div className="pet-friendly"><Badge pill>{menu.petFriendly === true ? "Pet Friendly" : null}</Badge></div>
                      <img className="img" src={menu.images._url} alt="logo" />
                    </div>
                    <div className="card-header">
                      <Card.Title className="placeName">
                        {menu.placeName}
                      </Card.Title>
                      <div>
                      <StarsRating disabled value={parseFloat(menu.ratings)} name="ratings" readonly />
                      </div>
                    </div>
                    <Card.Body className="">
                <Row className="m-0">
                        <div className="p-0">
                      <div className="place-type-div">{menu.placeType}</div>
                      <div>
                      <Stack gap={0} direction='vertical' className="availibility">
                <Stack gap={3} direction='horizontal'><span>Mon:</span> {menu.Monday}</Stack>
                <Stack gap={3} direction='horizontal'><span>Tues:</span> {menu.Tues}</Stack>
                <Stack gap={3} direction='horizontal'><span>Wed:</span> {menu.Wed}</Stack>
                <Stack gap={3} direction='horizontal'><span>Thurs:</span> {menu.Thurs}</Stack>
                <Stack gap={3} direction='horizontal'><span>Fri:</span> {menu.Fri}</Stack>
                <Stack gap={3} direction='horizontal'><span>Sat:</span> {menu.Sat}</Stack>
              </Stack>
                      </div>
                      </div>
                      </Row>
                      <Row>
                        <Col className="description">{menu.description}</Col>
                      </Row>
                      <Row>
                        {galleryData && galleryData.length > 0 && galleryData.map((data, i) => <Col className="mb-4 place-gallery" md={6} xs={12} key={i}>
                          <img role="button" src={data.galleryImage._url} alt="logo" onClick={() => { handleGalleryShow(); dispatch(setImageGalaryIndex(i)) }} />
                        </Col>)}
                      </Row>
                    </Card.Body>
                  </Card>) : <div className="d-flex justify-content-center align-items-center fs-2 fw-bold">Data Not Found!</div>}
                </div>
              </Col>
            </Row>
          </div>
          </div>
      </div>
      <Modal centered show={show} className="owner-ship-modal" onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="owner-ship-modal-body">
          <Form onSubmit={formik.handleSubmit}>
            <div className="form-title-div">
              <h4>Place Name</h4>
            </div>
            <Form.Group className="mb-4" controlId="placeName">
              <Form.Control
                type="text"
                maxLength={30}
                autoComplete="off"
                placeholder="Enter place name"
                value={formik.values.placeName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <div className="owner-ship-modal-btn">
              <Button
                className="insta-handle-btn"
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      
      <Modal className="owner-ship-modal" centered show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="owner-ship-modal-body">
        <div className="form-title-div">
          <h4 className="text-center" style={{color: "#1C3879", fontWeight: "bold"}}>Request has been send to the admin</h4>
          </div>
          <div className="owner-ship-message-modal-btn mt-4">
          <Button className="insta-handle-btn" variant="secondary" type="button" onClick={handleCloseMessage}>
            Close
          </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="owner-ship-modal" centered show={showConfirmDeleteDoc} onHide={handleCloseConfirmDeleteDoc}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="owner-ship-modal-body">
        <div className="form-title-div">
          <h4 className="text-center" style={{color: "#1C3879", fontWeight: "bold"}}>{`${deleteDocId && deleteDocId.file._name.split('_')[1]} Are you sure you want to delete this document!`}</h4>
          </div>
          <div className="owner-ship-message-modal-btn d-flex justify-content-end mt-4">
          <Button className="btn-no" variant="secondary" type="button" onClick={handleCloseConfirmDeleteDoc}>
            No
          </Button>
          <Button className="btn-yes ms-3" variant="secondary" type="button" onClick={handelDeleteDoc}>
            Yes
          </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal backdrop="static" size="lg" className="table-image-modal" show={showImage} onHide={handleCloseShowImage}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><div><img src={imageUrl} alt="logo" /></div></Modal.Body>
      </Modal>
      {showGallery === true ? <PlaceGalleryModal galleryIndex={galleryIndex} galaryLength={galleryData.length} handleClose={handleGalleryClose} showModal={showGallery}/> : null}
    </>
  );
};

export default LandingPage;
