import Home from './pages/admin-places/Home'
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Parse from 'parse';
import { ToastContainer } from 'react-toastify';
import { Route, Routes, HashRouter, Navigate } from 'react-router-dom';
import Details from './pages/admin-place-detail/Details';
import EditDetails from './pages/admin-place-edit/EditDetails';
import OwnerSignUp from './pages/po-signup/OwnerSignUp';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/admin-signin/Login';
import Protected from './components/auth/adminAuth';
import PlaceOwnerLogin from './pages/po-signin/PlaceOwnerLogin';
import LandingPage from './pages/po-home/PlaceOwnerHome';
import PoProtected from './components/auth/poAuth';
import FriendsOfEmvite from './pages/emviteinvite/FriendsOfEmvite';
import AuthInstagram from './pages/instagramauth/AuthInstagram';
import AdminList from './pages/admin-list/AdminList';
import WaitingList from './pages/admin-witing-list/WaitingList';
import { Loading } from './components/loader/FullPageLoader';
import OwnerRequest from './pages/admin-owner-request/OwnerRequest';
import PlaceOwnerInstaDetail from './pages/admin-place-owner-insta-detail/PlaceOwnerInstaDetail';
import AdminLogs from './pages/admin-logs/AdminLogs';
import PoPlaceEdit from './pages/po-place-edit/PoPlaceEdit';
import Configuration from './pages/admin-configuration/Configuration';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import AdminResetPassword from './pages/admin-reset-password/AdminResetPassword';
import PoForgotPassword from './pages/po-forgot-password/PoFrogotPassword';
import PoResetPassword from './pages/po-reset-password/PoResetPassword';
import Notification from './pages/admin-notification/Notification';

import AddAdmin from './pages/admin-add-form/addAdmin';
const app_id = process.env.REACT_APP_PARSE_APP_ID;
const host_url = process.env.REACT_APP_PARSE_HOST_URL;
const javascript_key = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;
Parse.initialize(app_id, javascript_key);
Parse.serverURL = host_url;


function App() {

  return (
    <>
      <HashRouter>
        <ToastContainer />
        <Routes>
          {/* <Route exact path="/friends-of-emvite" name="Invite" element={<FriendsOfEmvite />} /> */}
          <Route exact path="/places" name="Home" element={<Protected><Home /></Protected>} />
          <Route exact path="/places/signin" name="Signin" element={<Login />} />
          <Route exact path="/places/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
          <Route exact path="/places/reset-password/:token" name="Reset Password" element={<AdminResetPassword />} />
          <Route exact path="/places/detail/:id" name="Details" element={<Protected><Details /></Protected>} />
          <Route exact path="/places/activity-logs" name="Admin Logs" element={<Protected><AdminLogs /></Protected>} />
          <Route exact path="/places/notification" name="Notification" element={<Protected><Notification /></Protected>} />
          <Route exact path="/places/ownership-detail/:id" name="Place Owner Detail" element={<Protected><PlaceOwnerInstaDetail /></Protected>} />
          <Route exact path="/places/owner-request-list" name="Owner Request" element={<Protected><OwnerRequest /></Protected>} />
          <Route exact path="/places/add-admin" name="Admin List" element={<Protected><AdminList /></Protected>} />
          <Route exact path="/places/configuration" name="Configuration" element={<Protected><Configuration /></Protected>} />
          <Route exact path ="/admin/add/:id" name="Admin Add" element={<AddAdmin />} />
          <Route exact path="/places/foe-waiting-list" name="Waiting List" element={<Protected><WaitingList /></Protected>} />
          <Route exact path="/places/edit/:id" name="EditDetails" element={<Protected><EditDetails /></Protected>} />
          <Route exact path="/business/signin" name="Owner Signin" element={<PlaceOwnerLogin />} />
          <Route exact path="/business/forgot-password" name="Po Forgot Password" element={<PoForgotPassword />} />
          <Route exact path="/business/reset-password/:token" name="Po Reset Password" element={<PoResetPassword />} />
          <Route exact path="/business/signup" name="SignUp" element={<OwnerSignUp />} />
          <Route exact path="/business/home" name="Landing Page" element={<PoProtected><LandingPage /></PoProtected>} />
          <Route exact path="/business/place-edit/:id" name="Place Edit" element={<PoProtected><PoPlaceEdit /></PoProtected>} />
          <Route exact path="/po/auth/instagram" name="Instagram" element={<PoProtected><AuthInstagram /></PoProtected>} />
          <Route exact path="*" name="" element={<Loading/>} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
