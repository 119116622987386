import { Form, Button, Row, Col, Container, Modal } from "react-bootstrap";
import "./OwnerSignup.scss";
import { useFormik } from "formik";
import { HiUpload } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { registerOwnerAsync, selectStatus, verifyOtpAsync, selectMessage, clearMessage, emailSuccessMessage } from "./OwnerSignUpSlice";
import Parse from "parse";
import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.trim().length == 0)
    errors.name = "Name is required";

  if (!values.email || values.email.trim().length == 0) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password || values.password.trim().length == 0) {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be 8 characters or more";
  }

  if (!values.phone || values.phone.trim().length == 0) {
    errors.phone = "Phone number is required";
  }
  return errors;
};

let data = {
  name: "",
  email: "",
  password: "",
  phone: "",
  address: "",
  instagram: "",
  upload: "",
};

const OwnerSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailSuccess = useSelector(emailSuccessMessage)
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [resendOtpModal, setResendOtpModal] = useState(false)
  const [resendEmail, setResendEmail] = useState("")
  const OTP = JSON.parse(localStorage.getItem("otp"))
  const [valid, setValid] = useState(true)
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {setPasswordShown(!passwordShown)};
  const formik = useFormik({
    initialValues: data,
    validate,
    onSubmit: async (values) => {
      const query = new Parse.Query("Place_Owner");
      query.equalTo("email", values.email)
      let response = await query.find()
      if(response && response.length && response[0].id){
        toast.error("email already exist")
      }else {
      setShow(true)
      setResendEmail(values.email)
      let otp = generateOTP();
      localStorage.setItem("otp", JSON.stringify({"otp": otp, "time": new Date() }))
      await dispatch(verifyOtpAsync({email: values.email, OTP:otp}))     
      localStorage.setItem("po-data", JSON.stringify(values))
      }
    },
  });

  useEffect(() => {
    generateOTP()
  }, [])

  useEffect(() => {
    if(emailSuccess && emailSuccess === "The message was sent!"){
      toast.success(emailSuccess)
    }
    dispatch(clearMessage())
  }, [emailSuccess])

  function generateOTP() {
    let digits = '0123456789';
    let ran = '';
    for (let i = 0; i < 6; i++ ) {
        ran += digits[Math.floor(Math.random() * 10)];
    }
    return ran
  }

  const handleUpload = (e) => {
    const parseFile = new Parse.File("image-name", e.target.files[0]);
    formik.setFieldValue("upload", parseFile);
  };

  const handleClose = () => {setShow(false); localStorage.removeItem('otp'); setText("")}

  var today = new Date();
  var oldTime = new Date(OTP && OTP.time);
  var diffMs = (today - oldTime);
  var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

  const handleModalSubmit = async (e) => {
     e.preventDefault();
     if(diffMins >= 5){
      setResendOtpModal(true)
      setShow(false)
      setText("")
     } else {
     if(text == ""){
      setValid(false)
     }
     if(text !== OTP.otp){
      setValid(false)
     }
     if(text === OTP.otp){
      const data = localStorage.getItem("po-data")
      await dispatch(registerOwnerAsync(JSON.parse(data)));  
      localStorage.setItem("owner-emv-token", JSON.stringify(data))
      localStorage.removeItem('otp')
      navigate('/business/home')
     }
    }
  }

  const handleResendOtp = async () => {
    let otp = generateOTP();
    localStorage.setItem("otp", JSON.stringify({"otp": otp, "time": new Date()}))
    await dispatch(verifyOtpAsync({email: resendEmail, OTP:otp})) 
    setResendOtpModal(false)
    setShow(true)
  }

  const handleCloseResendOtpModal = () => {
    setResendOtpModal(false)
  }

  return (
    <>
      <div className="logo-header">
        <img src={`${process.env.PUBLIC_URL}/assets/emvite-logo.svg`} />
      </div>
      <div className="signup-main">
        <div className="overlay-img">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
          />
        </div>
        <div className="overlay-shap-left">
          <img src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`} />
        </div>
        <Container fluid className="signup-container">
          <Row className="align-items-center justify-content-evenly">
          <Col xs={12} md={4} lg={4}>
           <div className="register-left-side-div">
            <h4>Welcome to Emvite Places!</h4>
            <p>We're excited to have you join our community of local businesses and places. By registering with us, you'll be able to claim your business and update your listing information on Emvite. This will ensure that customers have access to accurate and up-to-date information about your business.
            </p>
            <p>To get started, please fill out the form below. You'll need to provide your business name, phone number, and email address. We'll also ask you to create a password so that you can access your Emvite Places account in the future.</p>
            <h4 className="mb-4">Let's get started!</h4>
            <p className="register-left-side-div-query">*If you have any questions or need help, please don't hesitate to reach out to us at places@emvitellc.com.</p>
           </div>
          </Col>
            <Col xs={12} md={6} lg={6}>
              <Form onSubmit={formik.handleSubmit}>
                <div className="register-form-div">
                  <div className="register-form-heading">
                        <h1>Sign Up</h1>
                      </div>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter name"
                          value={formik.values.name}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          autocomplete="off"
                        />
                        {formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name ? (
                          <Form.Label className="mt-1 error-msg-font mb-0">
                            <div>{formik.errors.name}</div>
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          autocomplete="off"
                        />
                        {formik.errors.email &&
                        formik.touched.email &&
                        formik.errors.email ? (
                          <Form.Label className="mt-1 error-msg-font mb-0">
                            <div>{formik.errors.email}</div>
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="password">
                        <Form.Label>Password</Form.Label>
                      <div className="show-hide-password">
                        <Form.Control
                          type={passwordShown ? "text" : "password"}
                          maxLength={30}
                          placeholder="Enter password"
                          value={formik.values.password}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          autocomplete="off"
                        />
                        {passwordShown === false ? <img src={`${process.env.PUBLIC_URL}/assets/eye-hide.svg`} alt="searchicon" className="show-hide-icon hide-icon" onClick={togglePassword} />
                        : <img src={`${process.env.PUBLIC_URL}/assets/eye-show.svg`} alt="searchicon" className="show-hide-icon show-icon" onClick={togglePassword}/>}
                        </div>
                        {formik.errors.password &&
                        formik.touched.password &&
                        formik.errors.password ? (
                          <Form.Label className="mt-1 error-msg-font mb-0">
                            <div>{formik.errors.password}</div>
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={10}
                          placeholder="Enter phone number"
                          value={formik.values.phone.replace(/^[A-Za-z\s]*$/, "")}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          autocomplete="off"
                        />
                        {formik.errors.phone &&
                        formik.touched.phone &&
                        formik.errors.phone ? (
                          <Form.Label className="mt-1 error-msg-font mb-0">
                            <div>{formik.errors.phone}</div>
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-4" controlId="instagram">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter instagram"
                          value={formik.values.instagram}
                          onChange={formik.handleChange}
                          autocomplete="off"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-4" controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          placeholder="Enter address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <Form.Label className="d-flex" role="button">
                        Upload Pdf/Photo
                        <Form.Group controlId="upload">
                          <Form.Control
                            type="file"
                            name="gallaryImages"
                            accept="image/*"
                            placeholder="Enter Day time"
                            onChange={handleUpload}
                            maxLength="7"
                            multiple="multiple"
                            style={{ display: "none" }}
                          />
                        </Form.Group>
                        <span className="upload">
                          <HiUpload />
                        </span>
                      </Form.Label>
                    </div>
                  </div>
                  <div className="text-center mt-3 register-owner-signup">
                    <Button
                      className="register-form-btn"
                      variant="primary"
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </div>
                </div>
              </Form>
              <div className="text-center mt-5 text-white">
                <h5>
                  Already account go to{" "}
                  <Link to="/business/signin" className="login-link">
                    Sign In
                  </Link>
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal backdrop="static" centered className="otp-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="otp-modal-body">
          <Form onSubmit={handleModalSubmit}>
            <div className="form-title-div">
              <h4>Verify OTP</h4>
            </div>
            <div className="text-center mb-3 text-info">
              <h5>Please Check Your Email</h5>
            </div>
          <Form.Group className="mb-4" controlId="phone">
                        <Form.Control
                          type="text"
                          maxLength={6}
                          placeholder="Enter otp"
                          value={text.replace(/^[A-Za-z\s]*$/, "")}
                          onBlur={formik.handleBlur}
                          onChange={(e) => setText(e.target.value)}
                          autocomplete="off"
                        />
                        {valid === false && <div className="text-danger mt-3 ">OTP invalid</div>}
                      </Form.Group>
                      <div className="otp-modal-btn">              
                        <Button variant="primary" type="submit">
                          Verify
                        </Button>
                     </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal backdrop="static" centered className="otp-modal" show={resendOtpModal} onHide={handleCloseResendOtpModal}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="otp-modal-body">
            <div className="text-center mb-3 text-info">
              <h5>OTP has been expired resend OTP</h5>
            </div>
                      <div className="otp-modal-btn">              
                        <Button variant="primary" type="button" onClick={handleResendOtp}>
                          Resend
                        </Button>
                     </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OwnerSignUp;
