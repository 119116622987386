import { Formik } from "formik";

import { Button, Form } from "react-bootstrap";
import './login.scss'
import { useDispatch,useSelector } from "react-redux";
import { loginAsync,isLoginError, isLoginSuccess, clearLoginMsg } from "./loginSlice";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";


const Login = () => {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const errorMsg = useSelector(isLoginError)
  const loginSuccess = useSelector(isLoginSuccess)
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {setPasswordShown(!passwordShown)};
  useEffect(()=> {
    if(localStorage.getItem("emv-token") !== null ){
      navigate("/places")
    }
  },[])

  useEffect(() => {
    if(errorMsg && errorMsg != ""){
      toast.error(errorMsg)
    }
    if(loginSuccess && loginSuccess != ""){
      toast.success(loginSuccess)
    }  
    dispatch(clearLoginMsg())
  }, [errorMsg, loginSuccess])
  return (
    <div >
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password) {
            errors.password = "Required";
          } else if (
            values.password.length <8

          ) {
            errors.password = "Password must be of length 8 characters.";
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await dispatch(loginAsync(values))
          
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <>
          {localStorage.getItem("emv-token")?navigate("/places"):<>
          <div className="login-main">
          <div className="logo-header">
              <img
                src={`${process.env.PUBLIC_URL}/assets/emvite-logo.svg`}
              />
          </div> 
          <div className="overlay-img">
              <img
                src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
              />
          </div>
          <div className="overlay-shap">
          <img
                src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`}
              /> 
          </div>
            <div className="login-form-main">
                <Form onSubmit={handleSubmit}>
                  
            <div className="login-form-heading">
                <h1>Sign In</h1>
              </div>
                  <Form.Group
                    className="mb-3"
                    controlId="email"
                    style={{ position: "relative" }}
                  >
                    <Form.Label className="loginLabel">Email</Form.Label>
                    <div className="login-control">
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      className="emailTextBox"
                      onChange={handleChange}
                      autocomplete="off"
                    />
                    <span className="login-control-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/Login form icons/Account.svg`}
                      height="30px"
                      width="30px"
                      className="emailIcon"
                    />
                    </span>
                    </div>
                    {errors.email !== undefined?<p className="text-danger">{errors.email}</p>:null}
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="password"
                    style={{ position: "relative" }}
                  >
                    <Form.Label className="loginLabel">
                      Password
                    </Form.Label>
                    <div className="login-control">
                      <span className="login-control-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/Login form icons/PasswordIcon.svg`}
                      height="30px"
                      width="30px"
                      className="passwordIcon"
                    />
                    </span>
                    <Form.Control
                          type={passwordShown ? "text" : "password"}
                      placeholder="Password"
                      className="passwordTextBox"
                      onChange={handleChange}
                    />
                    {passwordShown === false ? <img src={`${process.env.PUBLIC_URL}/assets/eye-hide.svg`} alt="searchicon" className="show-hide-icon hide-icon" onClick={togglePassword} />
                    : <img src={`${process.env.PUBLIC_URL}/assets/eye-show.svg`} alt="searchicon" className="show-hide-icon show-icon" onClick={togglePassword}/>}
                    </div>
                    {errors.password !== undefined?<p className="text-danger">{errors.password}</p>:null}
                  </Form.Group>
                  <div className="loginBtnContainer">
                    <Button type="submit" className="loginBtn">
                      Sign In
                    </Button>
                  </div>
                </Form>          
                <div className="admin-form-bottom-text">
                      <h5>
                        <Link className="login-link" to="/places/forgot-password">
                         Forgot Password ?
                        </Link>
                      </h5>
                    </div>
              </div>
            </div>
          </>}
          </>
        )}
      </Formik>
    </div>
  );
};

export default Login;

// style={{ position:"absolute",top:"40px",left:"5px",margin:"0px" ,display:"inline-block !important"}}
