import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
const initialState = {
  adminData: '',
  message: "",
  isLoading: false
};
export const addAdminEmailAsync = createAsyncThunk(
  "/send-adminEmail",
  async ({ email }) => {
    let id, params1, token;
    const query = new Parse.Query("Admin_User");

    query.equalTo('email', email);
    const response = await query.find();
    try {

      if (response.length == 0) {
        const newObject = new Parse.Object("Admin_User");
        // newObject.notEqualTo("email", email)
        // console.log("newObj", newObject)
        newObject.set("email", email);
        newObject.set("status", "invited");
        let invite = await newObject.save();
        id = invite.id;
        params1 = { "email": email, "id": id }
        token = localStorage.getItem("emv-token");
        const response = await Parse.Cloud.run('emailAddAdmin', params1)
        const logs = new Parse.Object("Admin_Activity_Log")
        logs.set("adminId", token)
        logs.set("type", "Admin Invited")
        await logs.save();
        return response
      } else {
        return 'already invited'
      }
    } catch (e) {
      console.log(e);
    }
  }
);
export const getAdminDataAsync = createAsyncThunk(
  '/get-admin-data',
  async ({ page, limit, searchKey }) => {
    try {
      const query = new Parse.Query("Admin_User");
      let count = await query.count();
      query.exclude('password')
      query.limit(limit);
      query.skip(page * limit);
      if (searchKey != "") {
        query.contains("email", searchKey)
        query.matches("email", searchKey, 'i')
      }

      const response = await query.find();
      console.log(response, "response")
      return { response, count }
    } catch (e) {
      console.log(e)
    }
  }
)
const addAdminOwnerDetailSlice = createSlice({
  name: "addAdminOwnerDetail",
  initialState: initialState,
  reducers: {
    resetMessage: (state, action) => {
      state.message = ""
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addAdminEmailAsync.pending, (state) => { state.status = false })
      .addCase(addAdminEmailAsync.fulfilled, (state, action) => {
        state.message = action.payload
      })
      .addCase(getAdminDataAsync.pending, (state, action) => { state.isLoading = true })
      .addCase(getAdminDataAsync.fulfilled, (state, action) => {
        const response = action.payload.response;
        const count = action.payload.count;
        let adminData = [];
        response.forEach((item) => {
          adminData.push(
            Object.assign({ ...item.attributes })
          );
        });
        state.adminData = adminData
        state.totalCount = count
        state.isLoading = false
      })
  }
})

export const { resetMessage } = addAdminOwnerDetailSlice.actions

export const EmailSent = (state) => state.addAdminOwnerDetail.message;
export const AdminData = (state) => state.addAdminOwnerDetail.adminData;
export const selectTotalCount = (state) => state.addAdminOwnerDetail.totalCount;
export const selectLoading = (state) => state.addAdminOwnerDetail.isLoading

export default addAdminOwnerDetailSlice.reducer;