import { Container, Table, Form, Button, Modal, Pagination } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AdminList.scss";
import { useFormik } from "formik";
import { addAdminEmailAsync, AdminData, getAdminDataAsync, selectTotalCount, EmailSent, resetMessage, selectLoading } from "./AdminListSlice";
import { toast } from "react-toastify";

export const loading = (
  <div className="loader">
    <div className="fp-container">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
  </div>
)

const validate = (values) => {
  const errors = {};

  if (!values.email || values.email.trim().length == 0) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};
export const debounceWait = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const AdminList = () => {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const totalCount = useSelector(selectTotalCount)
  const isLoading = useSelector(selectLoading)
  const message = useSelector(EmailSent)

  useEffect(() => {
    if (message && message === "Email Sent successfully!") {
      toast.success(message)
      formik.resetForm()
      setShow(false)
    }
    if(message && message === "already invited"){
      toast.error(message)
    }
    dispatch(resetMessage())
  }, [message])

  const dispatch = useDispatch()
  const data = useSelector(AdminData)

  let totalPage = Math.ceil(totalCount && totalCount / limit);
  const handleChange = debounceWait((e) => searchCategory(e));

  const searchCategory = async (e) => {
    setPage(1)
    setSearchKey(e.target.value);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
    setSearchKey("")
  };

  const handleNextPage = () => {
    setPage(page + 1);
    setSearchKey("")
  };
  let p = page - 1

  useEffect(() => {
    dispatch(getAdminDataAsync({ page: p, limit, searchKey }))
  }, [page, limit, searchKey])
  const formik = useFormik({
    initialValues: { email: "" },
    validate,
    onSubmit: async (values) => {
      dispatch(addAdminEmailAsync({ email: values.email }))
    },
  });
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }
  return (
    <>
      {isLoading && loading}
      <div className="admin-user-list">
        <div className="overlay-img">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
          />
        </div>
        <div className="overlay-shap">
          <img src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`} />
        </div>
        <div className="admin-list-main">
          <div className="d-flex justify-content-between admin-table-header">
            <Form.Group
              className="search-with-icon"
              controlId="formBasicSearch"
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/Search-gray.svg`}
                alt="searchicon"
              />
              <Form.Control
                type="text"
                placeholder="Search by Email"
                autoComplete="off"
                onChange={handleChange}
              />
            </Form.Group>
            <Button
              className="add-btn"
              variant="primary"
              type="button"
              onClick={handleShow}
            >
              Add
            </Button>
          </div>
          <div className="admin-table-main">
            <Table hover>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data && data.map((admin, i) => <tr key={admin.id}>
                  <td>{limit * (page - 1) + (i + 1)}</td>
                  <td>{admin.email}</td>
                  <td>{admin.firstname}</td>
                  <td>{admin.lastname}</td>
                  <td>{admin.status}</td>
                </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Pagination className="m-0">
              <Pagination.First
                className={(searchKey && totalPage) || page === 1 ? "diasbled" : ""}
                disabled={(searchKey && totalPage) || page === 1}
                onClick={() => setPage(1)}
              />
              <Pagination.Prev
                disabled={(searchKey && totalPage) || page === 1}
                onClick={handlePreviousPage}
              />
              <Pagination.Item key={page} active>
                {page}
              </Pagination.Item>
              <Pagination.Next
                disabled={(searchKey && totalPage.length) || page === totalPage}
                onClick={handleNextPage}
              />
              <Pagination.Last
                className={(searchKey && totalPage) || page === totalPage ? "disabled" : ""}
                disabled={(searchKey && totalPage) || page === totalPage}
                onClick={() => setPage(totalPage)}
              />
            </Pagination>
          </div>

        </div>
      </div>
      <Modal
        centered
        backdrop="static"
        className="admin-list-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="admin-modal-heding">Add Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-control">
            <Form onSubmit={formik.handleSubmit}>
              <div className="">
                <Form.Group className="mb-3" controlId="email">
                  {/* <Form.Label>Email</Form.Label> */}
                  <Form.Control type="email" placeholder="Enter email" value={formik.values.email} autocomplete="off" onChange={formik.handleChange} />
                    {formik.errors.email !== undefined?<p className="text-danger">{formik.errors.email}</p>:null}
                </Form.Group>
              </div>
              <div className="text-center">
                <Button className="loginBtn" variant="primary" type="submit" >
                  Send Invite
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminList;
