import { Table, Form, Pagination, Button } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getWaitingListAsync, selectWaitingData, selectTotalCount, selectWaitingListLoading } from './WaitingListSlice'
import { useNavigate } from "react-router-dom"
import Parse from "parse";
import './WaitingList.scss'
import moment from "moment"
import { toast } from "react-toastify"

export const loading = (
  <div className="loader">
        <div className="fp-container">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
      </div>
)

export const debounceWait = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const WaitingList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const waitingData = useSelector(selectWaitingData)
  const totalCount = useSelector(selectTotalCount)
  const waitingLoading = useSelector(selectWaitingListLoading)
  let totalPage = Math.ceil(totalCount && totalCount / limit);
  const handlePremiumMembership = async (email, id) => {
    let params = {
      email: email,
      objectId: id
    }
    const response = await Parse.Cloud.run('emailPremiumMembership', params)
    console.log("response", response)
    if(response === "Email Sent successfully!"){
      toast.success("Email Sent successfully!")
    }
    let p = page - 1
    dispatch(getWaitingListAsync({ page: p, limit, searchKey }));
  }
  const handleMultipleEmail = async() => {
   
    const response = await Parse.Cloud.run('sendMultipleEmail');
  }
  useEffect(() => {
    if (localStorage.getItem("emv-token") !== null) {
      let p = page - 1
      dispatch(getWaitingListAsync({ page: p, limit, searchKey }));
    } else {
      navigate("/places/signin");
    }
  }, [page, limit, searchKey])
    
  const handleChange = debounceWait((e) => searchCategory(e));

  const searchCategory = async (e) => {
    setPage(1)
    setSearchKey(e.target.value);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
    setSearchKey("")
  };

  const handleNextPage = () => {
    setPage(page + 1);
    setSearchKey("")
  };

  return (
       <>
        {waitingLoading && loading}
        <div className="admin-user-list">
      <div className="overlay-img">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
          />
        </div>
        <div className="overlay-shap">
          <img src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`} />
        </div>
        <div className="admin-list-main">
          <div className="w-100 mb-3 d-flex justify-content-end">
        <Button className="inviteall-btn" style={{}} onClick={() => handleMultipleEmail()}>Invite All</Button> 
        </div>
          <div className="d-flex justify-content-between align-items-center admin-table-header">
      
            <Form.Group
              className="search-with-icon"
              controlId="formBasicSearch"
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/Search-gray.svg`}
                alt="searchicon"
              />
              <Form.Control
                type="text"
                placeholder="Search by email"
                autoComplete="off"
                onChange={handleChange}
              />
            </Form.Group>
            <h6>
             
            
              Total Count : <span className="fw-bold">{totalCount}</span></h6>
          </div>
          <div className="admin-table-main no-scroll">
            <Table hover>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Email</th>
                  <th>CreatedAt</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {waitingData && waitingData.map((data, i) => <tr key={i}>
                  <td>{limit * (page - 1) + (i + 1)}</td>
                  <td>{data.email}</td>
                  <td className="min-200">{moment.utc(data.createdAt, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")}</td>
                  {
                    !data.inviteToken ?     <td><Button className="invite-btn" onClick={() => handlePremiumMembership(data.email, data.ObjectId)}>send Invite</Button></td> : ''
            
                  }
                    {/* {data.invitedToken == 'undefined' ?<td><Button onClick={() => handlePremiumMembership(data.email, data.ObjectId)}>send Invite</Button></td> :''} */}
                </tr>)}
              </tbody>
            </Table>

          </div>
          <div className="d-flex justify-content-end mt-3">
            <Pagination className="m-0">
              <Pagination.First
                className={(searchKey && totalPage) || page === 1 ? "diasbled" : ""}
                disabled={(searchKey && totalPage) || page === 1}
                onClick={() => setPage(1)}
              />
              <Pagination.Prev
                disabled={(searchKey && totalPage) || page === 1}
                onClick={handlePreviousPage}
              />
              <Pagination.Item key={page} active>
                {page}
              </Pagination.Item>
              <Pagination.Next
                disabled={(searchKey && totalPage.length) || page === totalPage}
                onClick={handleNextPage}
              />
              <Pagination.Last
                className={(searchKey && totalPage) || page === totalPage ? "disabled" : ""}
                disabled={(searchKey && totalPage) || page === totalPage}
                onClick={() => setPage(totalPage)}
              />
            </Pagination>
          </div>
        </div>
      </div>
    </>
  )
}

export default WaitingList