import { Row, Col, Card, Form, Button } from "react-bootstrap"
import { useEffect } from "react";
import { useFormik } from "formik";
import { postConfigAsync, getConfigAsync, selectConfigData, selectIsLoading } from './ConfigurationSlice'
import { useDispatch, useSelector } from "react-redux";
import './configuration.scss'

export const loading = (
    <div className="loader">
          <div className="fp-container">
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
        </div>
  )

const Configuration = () => {
    const dispatch = useDispatch()
    const configData = useSelector(selectConfigData)
    const isLoading = useSelector(selectIsLoading)

    let data = {
        objectId: configData.objectId,
        allowSwap: configData.allowSwap,
        allowEmojis: configData.allowEmojis
    }

    useEffect(() => {
        dispatch(getConfigAsync())
    }, [])
    const formik = useFormik({
        initialValues: data,
        enableReinitialize:true,
        onSubmit: async (values) => {
         await dispatch(postConfigAsync(values))
         await dispatch(getConfigAsync())
        },
      });
    return (
        <>
        {isLoading && loading}
        <div className="config-main">
            <Row className="config-row">
                <Col md={4} xs={12}>
         <Card>
         <Card.Body>
            <Form onSubmit={formik.handleSubmit}>   
            <h4 className="text-center m-3">Configuration</h4>
            <Form.Group className="mb-4" controlId="allowSwap">
                <Form.Label>Allow Swipe</Form.Label>
                 <Form.Control
                  type="number"
                  placeholder="Enter swipe"
                  value={formik.values.allowSwap}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="allowEmojis">
                <Form.Label>Allow Emojis</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter allow emojis"
                  value={formik.values.allowEmojis}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <div className="text-center">
                <Button className="config-btn" type="submit">Submit</Button>
              </div>
            </Form>
         </Card.Body>
         </Card>
         </Col>
         <Col md={8} xs={12}></Col>
         </Row>
        </div>
        </>
    )
}

export default Configuration