import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Pagination, Badge } from "react-bootstrap";
import { getData, getFoodData, updateIndex, getTotalCount, selectSearchLoading } from "../../store/slices/Slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./home.scss";
import { EmojiIcons } from "../../constant";


export const debounceWait = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const loading = (
  <div className="loader">
        <div className="fp-container">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
      </div>
)

export default function Home() {
  const data = useSelector(getData);
  const totalCount = useSelector(getTotalCount);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectSearchLoading);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchKey, setSearchKey] = useState("");

  let totalPage = Math.ceil(totalCount && totalCount / limit);
  useEffect(() => {
    if (localStorage.getItem("emv-token") !== null) {
      let p = page -1
      dispatch(getFoodData({ page: p, limit, searchKey }));
    } else {
      navigate("/places/signin");
    }
  }, [page, limit, searchKey]);

  const handleChange = debounceWait((e) => searchCategory(e));

  const searchCategory = async (e) => {
    setPage(1)
    setSearchKey(e.target.value);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
    setSearchKey("")
  };

  const handleNextPage = () => {
    setPage(page + 1);
    setSearchKey("")
  };

  const getIcons = (name) => {
    let foodItems = EmojiIcons.foodItemsList.find((fi) => fi.name === name);
    let activityItems = EmojiIcons.activityItemsList.find((ai) => ai.name === name);
    if(foodItems) return foodItems.image;
    if(activityItems) return activityItems.image;
  }

  return (
    <>
    {isLoading && loading}
    <div className="main-div">
      <span className="dot">
        <img
          src={`${process.env.PUBLIC_URL}/assets/LoginImage.png`}
          width="600px"
          height="600px"
          style={{ zIndex: "99999", position: "relative" }}
        />
      </span>
      <div>
        <Row className="m-0">
          <div className="ser-pagi-div">
          <div>
          <Form.Group
            className="search-with-icon mb-3"
            controlId="formBasicSearch"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/Search-gray.svg`}
              alt="searchicon"
            />
            <Form.Control
              type="text"
              placeholder="Search"
              autoComplete="off"
              onChange={handleChange}
            />
          </Form.Group>
          </div>
          <div className="d-flex justify-content-end">
          <Pagination>
            <Pagination.First
              className={(searchKey && totalPage) || page === 1 ? "diasbled" : ""}
              disabled={(searchKey && totalPage) || page === 1}
              onClick={() => setPage(1)}
            />
            <Pagination.Prev
              disabled={(searchKey && totalPage) || page === 1}
              onClick={handlePreviousPage}
            />
            <Pagination.Item key={page} active>
              {page}
            </Pagination.Item>
            <Pagination.Next
              disabled={(searchKey && totalPage.length) || page === totalPage}
              onClick={handleNextPage}
            />
            <Pagination.Last
              className={(searchKey && totalPage) || page === totalPage ? "disabled" : ""}
              disabled={(searchKey && totalPage) || page === totalPage}
              onClick={() => setPage(totalPage)}
            />
          </Pagination>
        </div>
        </div>
          {data &&
            data.length > 0 &&
            data.map((menu, index) => {
              return (
                <Col md={4} lg={3} sm={6} className="mb-7" key={index}>
                  <Card
                    key={index}
                    onClick={() => {
                      dispatch(updateIndex(index));
                      navigate(`/places/detail/${menu.ObjectId}`);
                    }}
                    className="h-100  "
                  >
                    <div className="card-container">
                      {menu.icons ? <div className="emojiContainer">
                        {menu.icons.map((t, i) => (
                          getIcons(t) != undefined  ? <img
                            key={i}
                            className="icons-img me-2"
                            src={`${process.env.PUBLIC_URL}/assets/Emojis/${getIcons(t)}`}
                          /> : ''
                        ))}
                        {/* <img className="icons-img" src={`${process.env.PUBLIC_URL}/assets/Emojis/${menu.icons}.png`} width="100%" /> */}
                      </div> : ""}
                      <div className="switch-div"><Badge pill>{menu.petFriendly === true ? "Pet Friendly" : null}</Badge></div>
                      <img className="img" src={menu.images._url} />
                    </div>
                    <div className="card-header">
                      <Card.Title className="placeName">
                        {menu.placeName}
                      </Card.Title>
                    </div>
                    <Card.Body>
                      <Row>
                        <Col className="description">{menu.description}</Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
    </>
  );
}
