// export const dataObj = {
//     client_id: 197678946191323,
//     client_secret: "4ab08723dd2386267a73cf19a464866b",
//     grant_type: "authorization_code",
//     redirect_uri: "https://emvitedev.b4a.app/auth/instagram.html",    
// }

export const dataObj = {
  client_id: 716895753181243,
  client_secret: "a0431f7fcd9830f8c3bd9264701e20fb",
  grant_type: "authorization_code",
  redirect_uri: "https://emvitedev.b4a.app/auth/instagram.html",    
}

export const EmojiIcons = {
    foodItemsList: [
      { name: "Salads/Vegetarian", image : 'Salad.png' },
      { name: "Pizza", image : 'Pizza.png' },
      { name: "Beer", image : 'Beer.png' },
      { name: "Hot Dog", image : 'Hot Dog.png' },
      { name: "Sandwich", image : 'Sandwich.png' },
      { name: "Burgers", image : 'Burgers.png' },
      { name: "Champagne", image : 'Champagne.png' },
      { name: "Cocktails", image : 'Cocktails.png' },
      { name: "Coffee", image : 'Coffee.png' },
      { name: "Food Truck", image : 'Food Truck.png' },
      { name: "Hard liquor", image : 'Hard liquor.png' },
      { name: "Pasta", image: "Pasta.png" },
      { name: "Seafood", image: "Seafood.png" },
      { name: "Smoothies", image: "Smoothies.png" },
      { name: "Maxican Food", image: "Tacos.png" },
      { name: "Tea", image: "Tea.png" },
      { name: "Wine", image: "Wine.png" },
      { name: "Breakfast", image: "Breakfast.png"},
      { name: "Ice Cream", image: "Ice Cream.png"},
      { name: "Comida criolla", image: "Comida criolla.png"},
      { name: "Asian/Sushi", image: "Sushi.png"},
      { name: "Steak Lover", image: "Steak.png"}
    ],
    activityItemsList: [
      { name: "Gaming", image: "Gaming.png" },
      { name: "Soccer", image: "Soccer.png" },
      { name: "Baseball", image: "Baseball.png" },
      { name: "Tennis", image: "Tennis.png" },
      { name: "Biking", image: "Biking.png" },
      { name: "Golf", image: "Golf.png" },
      { name: "Basketball", image: "Basketball.png" },
      { name: "Surfing", image: "Surfing.png" },
      { name: "Yoga", image: "Yoga.png" },
      { name: "Bowling", image: "Bowling.png" },
      { name: "Mixed Martial Arts", image: "Mixed Martial Arts.png" },
      { name: "Pool", image: "Pool.png" },
      { name: "Performing arts", image: "Performing arts.png" },
      { name: "Karaoke", image: "Karaoke.png" },
      { name: "Shopping", image: "Shopping.png" },
      { name: "Photography", image: "Photography.png" },
      { name: "Reading/Study", image: "Reading.png" },
      { name: "Racing", image: "Racing.png" },
      { name: "Dancing", image: "Dancing.png" },
      { name: "Beach", image: "Beach.png" },
      { name: "Music", image: "Music.png" },
      { name: "Concert", image: "Concert.png" },
      { name: "Dog walk", image: "Dog walk.png" },
      { name: "Chat", image: "Chat.png"},
      { name: "Gym", image: "Gym.png"},
      { name: "Party", image: "Party.png"},
      { name: "Swimming", image: "Swimming.png"},
      { name: "Volleyball", image: "Volleyball.png"},
      { name: "Movie Theather", image: "Movie Theather.png"},
      { name: "Ping Pong", image: "Table Tennis.png"},
      { name: "Boxing", image: "Boxing.png"},
      { name: "Jogging", image: "Jogging.png"},
      { name: "BBQ", image: "BBQ.png"},
      { name: "American Football", image: "American Football.png" },
    ],
  };