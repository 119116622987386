import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'
import { addCodeInstaAsync, postInstaAsync, selectUserData, selectMediaData, selectToken } from './AuthInstagramSlice'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
import { dataObj } from '../../constant'

const AuthInstagram = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const userData = useSelector(selectUserData)
  const mediaData = useSelector(selectMediaData)
  const token = useSelector(selectToken)
  console.log("user", userData)
  console.log("media", mediaData)
  console.log("token", token)
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code")

  let data = {
    client_id: dataObj.client_id,
    client_secret: dataObj.client_secret,
    grant_type: dataObj.grant_type,
    redirect_uri: dataObj.redirect_uri,
    code: code
  }
  // const postData = async () => {
  //   const response = await axios.post('https://api.instagram.com/oauth/access_token', data);
  //   return response
  // }

  useEffect(() => {
    dispatch(addCodeInstaAsync(code))
    if(code != null || code!= undefined){
      dispatch(postInstaAsync(data))
    }
  }, [])

  if(token != null || token != undefined){
     navigate('/business/home')
  }
  return (
    <>
      <div>
        <div className="loader">
          <div className="fp-container">
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
        </div>
      </div>
    </>
  )
}

export default AuthInstagram