import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
// import {getGalleryImageWithId,incrementImageGalaryIndex,decrementImageGalaryIndex,getMaximumGalarySize} from '../../store/slices/Slice'
import {getGalleryImageWithId,incrementImageGalaryIndex,decrementImageGalaryIndex} from '../../pages/admin-place-detail/DetailsSlice'
import './Gallery.scss'

export default function ImageGalaryModal(props) {
  const dispatch = useDispatch();
    const{showModal, handleClose,galleryIndex, galaryLength}=props;
    const currentImage=useSelector( getGalleryImageWithId(galleryIndex))
    // const galleryImageSize=useSelector(getMaximumGalarySize)
  return (
    
      <Modal backdrop="static" className='gallery-modal' size="lg" show={showModal} onHide={()=> handleClose()} centered animation={false}>
          <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body className='gallery-modal-body'>
       <div className='gallery-close'>   
      <img role="button" src={`${process.env.PUBLIC_URL}/assets/download.svg`} alt="logo" onClick={()=> handleClose()}/>
      </div>
        <img alt="image" height="600px" width="100%" src={currentImage} />
        <div className='gallery-modal-btn'>
      <Button variant="primary" className='gallery-prev' onClick={()=>dispatch(decrementImageGalaryIndex())} disabled={galleryIndex === 0 ? true:false}><img src={`${process.env.PUBLIC_URL}/assets/Arow_Left.png`}/></Button>
      <Button variant="primary" className='gallery-next' onClick={()=>{dispatch(incrementImageGalaryIndex())}} disabled={galleryIndex + 1 ===  galaryLength? true:false}><img src={`${process.env.PUBLIC_URL}/assets/Arow_Right.png`}/></Button>
      </div>
        </Modal.Body>
      </Modal>
    
  )
}
