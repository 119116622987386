import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";

const initialState = {
  status: false,
  loading: false,
  msg: "",
  emailSuccess: ""
};


export const registerOwnerAsync = createAsyncThunk(
  "/register",
  async ({ email, password, name, instagram, phone, upload, address }) => {
    const newObject = new Parse.Object("Place_Owner");
    newObject.set("email", email);
    newObject.set("password", password);
    newObject.set("name", name);
    newObject.set("instagram", instagram);
    newObject.set("phone", phone);
    if(upload != ""){
    newObject.set("upload", upload);
    }
    newObject.set("address", address);
    try {
      const response = await newObject.save();
      return response;
    } catch (error) {
      console.error("Error while creating records: ", error);
    }
  }
);

export const verifyOtpAsync = createAsyncThunk(
  "/otp",
  async ({email, OTP}) => {
    const params1 = {"toEmail": email, "otp": OTP }
  try {
    const response = await Parse.Cloud.run('poRegistrationOTP', params1)
    return response
  } catch (error) {
    console.error("Error while creating records: ", error);
  }
}
);

const registerSlice = createSlice({
  name: "register",
  initialState: initialState,

  reducers: {
    clearMessage: (state) => {
      state.msg = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerOwnerAsync.fulfilled, (state, action) => {
        state.msg = "Registered successfully"
        state.status = true;
        state.loading = false;
      })
      .addCase(registerOwnerAsync.pending, (state, action) => {
        state.status = false;
        state.loading = true;
      })
      .addCase(verifyOtpAsync.fulfilled, (state, action) => {
        state.emailSuccess = action.payload
        state.status = true;
        state.loading = false;
      })
      .addCase(verifyOtpAsync.pending, (state, action) => {
        state.status = false;
        state.loading = true;
      });
  },
});

export const { clearMessage } = registerSlice.actions;

export const selectStatus = (state) => state.register.status;
export const selectMessage = (state) => state.register.msg;
export const emailSuccessMessage = (state) => state.register.emailSuccess;

export default registerSlice.reducer;
