import { useEffect, useState } from "react";
import { Form, Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerShipListAsync, selectOwnerShipData, selectTotalCount, selectOwnerSHipLoading } from './OwnerRequestSlice'
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const loading = (
  <div className="loader">
        <div className="fp-container">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
      </div>
)

export const debounceWait = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const OwnerRequest = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const ownerData = useSelector(selectOwnerShipData)
    const totalCount = useSelector(selectTotalCount)
    const ownerShipLoading = useSelector(selectOwnerSHipLoading)
    const [page, setPage] = useState(1);
    const [limit] = useState(20);
    const [searchKey, setSearchKey] = useState("");

    let totalPage = Math.ceil(totalCount && totalCount / limit);

    useEffect(() => {
      let p = page - 1
        dispatch(getOwnerShipListAsync({ page: p, limit, searchKey }))
    }, [page, limit, searchKey])

    const handelNavigate = (data) => {
       navigate(`/places/ownership-detail/${data.placeOwnerId}`)
    }

    const handleChange = debounceWait((e) => searchCategory(e));

    const searchCategory = async (e) => {
      setPage(1)
      setSearchKey(e.target.value);
    };
  
      const handlePreviousPage = () => {
          setPage(page - 1);
          setSearchKey("")
        };
      
        const handleNextPage = () => {
          setPage(page + 1);
          setSearchKey("")
        };

    return (
        <>
        {ownerShipLoading && loading}
        <div className="admin-user-list">
      <div className="overlay-img">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Login-right-overly-bg.svg`}
          />
        </div>
        <div className="overlay-shap">
          <img src={`${process.env.PUBLIC_URL}/assets/Login-left-shape.svg`} />
        </div>
        <div className="admin-list-main">
          <div className="d-flex justify-content-between align-items-center admin-table-header">
            <Form.Group
              className="search-with-icon"
              controlId="formBasicSearch"
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/Search-gray.svg`}
                alt="searchicon"
              />
              <Form.Control
                type="text"
                placeholder="Search by place name"
                autoComplete="off"
                  onChange={handleChange}
              />
            </Form.Group>
            <h6>Total Count : <span className="fw-bold">{totalCount}</span></h6>
          </div>
          <div className="admin-table-main">
            <Table hover>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Owner Name</th>
                  <th>Place Name</th>
                  <th>CreatedAt</th>
                </tr>
              </thead>
              <tbody>
                {ownerData && ownerData.map((data, i) => <tr key={i}>
                  <td>{limit * (page-1) + (i+1)}</td>
                  <td><span style={{cursor: "pointer"}} onClick={() => handelNavigate(data)}>{data.placeOwnerName}</span></td>
                  <td>{data.placeName}</td>
                  <td className="min-200">{moment.utc(data.createdAt, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")}</td>
                </tr>)}
              </tbody>
            </Table>
            
          </div>
          <div className="d-flex justify-content-end mt-3">
          <Pagination className="m-0">
            <Pagination.First
              className={(searchKey && totalPage) || page === 1 ? "diasbled" : ""}
              disabled={(searchKey && totalPage) || page === 1}
              onClick={() => setPage(1)}
            />
            <Pagination.Prev
              disabled={(searchKey && totalPage) || page === 1}
              onClick={handlePreviousPage}
            />
            <Pagination.Item key={page} active>
              {page}
            </Pagination.Item>
            <Pagination.Next
              disabled={(searchKey && totalPage.length) || page === totalPage}
              onClick={handleNextPage}
            />
            <Pagination.Last
              className={(searchKey && totalPage) || page === totalPage ? "disabled" : ""}
              disabled={(searchKey && totalPage) || page === totalPage}
              onClick={() => setPage(totalPage)}
            />
          </Pagination>
        </div>
        </div>
      </div>
        </>
    )
}

export default OwnerRequest;