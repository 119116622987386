import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Parse from "parse";
const initialState = {
  ownerShipData: [],
  totalCount: 0,
  ownerShipLoading: false
};
export const getOwnerShipListAsync = createAsyncThunk(
  "/get-owner",
  async ({page, limit, searchKey}) => {
    try {
      const query = new Parse.Query("PlaceOwnerShipRequest");
      let count = await query.count();
      query.limit(limit);
      query.skip(page * limit);
      if (searchKey != "") {
        query.contains("placeName", searchKey)
        query.matches("placeName", searchKey, 'i')
      }
      const response = await query.find();
      let newData = [];
      if (response) {
        response.forEach((item) => {
          newData.push(Object.assign({ ...item.attributes }));
        });

        let arr = [];
        for (let i = 0; i < newData.length; i++) {
          arr.push(newData[i].placeOwnerId);
        }

        let names = {};
        const query = new Parse.Query("Place_Owner");
        query.containedIn("objectId", arr);
        let users = await query.find();
        for (var i = 0; i < users.length; i++) {
          names[users[i].id] = users[i].get("name");
        }
        for (let i = 0; i < newData.length; i++) {
          newData[i].placeOwnerName = names[newData[i].placeOwnerId];
        }
        console.log('newData', newData)
      }
      return {newData, count};
    } catch (e) {
      console.log(e);
    }
  }
);

const ownerShipSlice = createSlice({
  name: "ownership",
  initialState: initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getOwnerShipListAsync.pending, (state, action) => { state.ownerShipLoading = true})
    .addCase(getOwnerShipListAsync.fulfilled, (state, action) => {
      state.ownerShipData = action.payload.newData;
      state.totalCount = action.payload.count
      state.ownerShipLoading = false
    });
  },
});

export const selectOwnerShipData = (state) => state.ownership.ownerShipData;
  export const selectTotalCount = (state) => state.ownership.totalCount;
  export const selectOwnerSHipLoading = (state) => state.ownership.ownerShipLoading;

export default ownerShipSlice.reducer;
